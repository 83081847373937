import { CalendarMonthOutlined, CloseOutlined } from '@mui/icons-material';
import { InputAdornment, Menu, styled, TextField } from '@mui/material';
import es from 'date-fns/locale/es';
import { MouseEvent, useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { dateFormater } from '../../utils';

const DatePicker = styled(TextField)(() => ({
	'&, &>*, &>*>*': {
		cursor: 'pointer',
	},
	width: '17rem',
	margin: '8px',
	'@media(max-width:700px)': {
		width: '100%',
	},
}));
const Calendar = styled(DateRange)(() => ({
	'&>.rdrDateDisplayWrapper': {
		display: 'none',
	},
}));

export default function DateRangePicker({
	onDateChange,
}: {
	onDateChange: Function;
}) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [state, setState] = useState<Range[]>([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	]);
	const [count, setCount] = useState(0);
	const [dateDisplay, setDateDisplay] = useState('');
	const handleDateChange = (item: { [key: string]: Range }) => {
		setCount(count + 1);

		setState([item.selection]);

		if (count === 1) {
			onDateChange(item.selection);
			setCount(0);
			setAnchorEl(null);
			setDateDisplay(changeDateDisplay(item.selection));
		}
	};

	const handleClick = (event: MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const changeDateDisplay = (range: Range) => {
		if (!range.startDate || !range.endDate) return '';
		const { startDate, endDate } = range;
		return `${dateFormater(startDate)} - ${dateFormater(endDate)}`;
	};
	const clearDateDisplay = (e: MouseEvent) => {
		e.stopPropagation();
		setState([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			},
		]);
		setDateDisplay('');
		onDateChange(null);
	};

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<DatePicker
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				size="small"
				placeholder="Entre fechas..."
				variant="outlined"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<CalendarMonthOutlined />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="end">
							{dateDisplay && <CloseOutlined onClick={clearDateDisplay} />}
						</InputAdornment>
					),
				}}
				value={dateDisplay}
				autoComplete="off"
			/>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<Calendar
					editableDateInputs={true}
					moveRangeOnFirstSelection={false}
					ranges={state}
					locale={es}
					onChange={handleDateChange}
					maxDate={new Date()}
					minDate={new Date('01/01/2010')}
				/>
			</Menu>
		</div>
	);
}
