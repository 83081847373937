export enum Color {
	Red = 'red',
	Blue = 'blue',
	Green = 'green',
	Lime = 'lime',
	Gold = 'gold',
	Orange = 'orange',
	Purple = 'purple',
	Pink = 'pink',
	Cyan = 'cyan',
	Magenta = 'magenta',
	Volcano = 'volcano',
	GeekBlue = 'geekblue',
}

export const colorsArray = Object.values(Color);
