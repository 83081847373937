import ContainerHeader from '../ContainerHeader';
import BoxContainer from '../BoxContainer';
import { ReactNode } from 'react';

export interface InfoContainerProp {
	title: string;
	width: string;
	icon?: ReactNode;
	children?: ReactNode;
	align?: string;
	little?: boolean;
	flexDirection?: string;
}
export default function InfoContainer({
	title,
	width,
	icon,
	align = 'center',
	children,
	little = false,
	flexDirection = 'row',
}: InfoContainerProp) {
	return (
		<BoxContainer width={width} little={little}>
			<ContainerHeader icon={icon}>{title}</ContainerHeader>
			<div
				style={{
					display: 'flex',
					justifyContent: align,
					marginTop: '0.75rem',
					flexDirection: flexDirection === 'column' ? 'column' : 'row',
				}}
			>
				{children}
			</div>
		</BoxContainer>
	);
}
