import {
	AddCircleOutline,
	FileDownloadOutlined,
	FilterListOutlined,
} from '@mui/icons-material';
import { Button, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import axios from 'axios';

import MenuSelect from '../MenuSelect';

import { GroupLabels } from '../../entities/Group';
import { State } from '../../entities/State';

import DateRangePicker from '../DateRange';
import { Range } from 'react-date-range';
import Pill from '../Pill';
import { GroupType } from '../../entities/GroupType';
import { Color } from '../../enums/pill-colors';
import SearchableSelect from '../SearchableSelect';
import InteractiveToggle from '../inactiveToggle';

const MenuContainer = styled('div')(() => ({
	padding: '8px 8px 0px 8px',
}));
const ButtonsContainer = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	paddingBottom: '4px',
	'#addButton': { display: 'none' },
	'@media(max-width:700px)': {
		display: 'flex',
		flexDirection: 'column',
		'&>div': {
			marginBlock: '5px',
		},
		'#search': {
			order: 1,
		},
		'#buttons': {
			order: 2,
		},
		'#add': { display: 'none' },
		'#addButton': { display: 'inline-flex' },
	},
}));
const MenuButton = styled(Button)(() => ({
	fontWeight: '600',
	fontSize: '0.8125rem',
}));
const FiltersContainer = styled('div', {
	shouldForwardProp: (propName) => propName !== 'showFilters',
})<{ showFilters: boolean }>(({ showFilters }) => ({
	maxHeight: showFilters ? '70px' : '0',
	paddingBottom: showFilters ? '7px' : '0',
	transition: 'max-height 0.2s ease-out, padding 0.2s ease-out',
	overflow: 'hidden',
	display: 'flex',
	'@media(max-width:700px)': {
		maxHeight: showFilters ? '160px' : '0',
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gridTemplateRows: '1fr',
		gap: '4px',

		'div:nth-of-type(1)': {
			gridColumn: '1 / span 1',
		},

		'div:nth-of-type(2)': {
			gridColumn: '2 / span 1',
		},

		'div:nth-of-type(3)': {
			gridRow: '2',
			gridColumn: '1 / span 2',
		},
	},
}));

export interface MenuTableProps {
	applyFilters: Function;
	handleClickAdd: () => void;
	searchbar?: boolean;
	groupsFilter?: boolean;
	groupsTypeFilter?: boolean;
	preferedZoneFilter?: boolean;
	stateFilter?: boolean;
	dateFilter?: boolean;
	exportFiles?: boolean;
	inactiveFilter?: boolean;
	noAdd?: boolean;
}

export default function MenuTable({
	applyFilters,
	handleClickAdd,
	searchbar,
	groupsFilter,
	groupsTypeFilter,
	preferedZoneFilter,
	stateFilter,
	dateFilter,
	exportFiles,
	inactiveFilter,
	noAdd = false,
}: MenuTableProps) {
	const [filters, setFilters] = useState<{
		state?: string;
		group?: string;
		start_date?: string;
		end_date?: string;
		prefered_zone?: string;
		q: string;
	}>({
		q: '',
	});

	const [showFilters, setShowFilters] = useState(false);
	const [states, setStates] = useState<State[]>([]);
	const [groups, setGroups] = useState<GroupLabels[]>([]);
	const [groupTypes, setGroupsTypes] = useState<GroupType[]>([]);

	const hasFilters =
		groupsFilter ||
		groupsTypeFilter ||
		preferedZoneFilter ||
		stateFilter ||
		dateFilter ||
		exportFiles ||
		inactiveFilter;

	useEffect(() => {
		if (stateFilter) {
			axios.request({ method: 'GET', url: `/states` }).then((response) => {
				setStates(response.data.data);
			});
		}

		if (groupsFilter) {
			axios
				.request({ method: 'GET', url: `/groups/labels` })
				.then((response) => {
					setGroups(response.data.data);
				});
		}

		if (groupsTypeFilter) {
			axios.request({ method: 'GET', url: `/group_type` }).then((response) => {
				setGroupsTypes(response.data.data);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const query = '&' + new URLSearchParams(filters);

		applyFilters(query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const handleChange = (e: SelectChangeEvent<unknown>) => {
		setFilters({
			...filters,
			[e.target.name]: `${e.target.value}`,
		});
	};

	const onDateChange = (range: Range) => {
		const origin = range?.startDate || new Date('01/01/1900');
		const end = range?.endDate || new Date('10/01/9900');
		const startDate = origin.toISOString().split('T')[0];
		const endDate = end.toISOString().split('T')[0];
		setFilters({
			...filters,
			start_date: startDate,
			end_date: endDate,
		});
	};

	return (
		<MenuContainer>
			<ButtonsContainer>
				<div id="buttons">
					{hasFilters && (
						<MenuButton
							startIcon={<FilterListOutlined />}
							variant="text"
							onClick={() => setShowFilters(!showFilters)}
							role="button"
						>
							Filtros
						</MenuButton>
					)}
					{exportFiles && (
						<MenuButton startIcon={<FileDownloadOutlined />} variant="text">
							Exportar
						</MenuButton>
					)}
					{!noAdd ? (
						<MenuButton
							startIcon={<AddCircleOutline />}
							variant="text"
							id="addButton"
							onClick={handleClickAdd}
						>
							Agregar
						</MenuButton>
					) : (
						<div></div>
					)}
				</div>
				{searchbar && (
					<GridToolbarQuickFilter
						value={filters.q}
						onChange={(e) => setFilters({ ...filters, q: e.target.value })}
						placeholder="Buscar..."
						id="search"
						role="search"
					/>
				)}

				{!noAdd ? (
					<Button
						variant="contained"
						startIcon={<AddCircleOutline />}
						id="add"
						onClick={handleClickAdd}
						role="button"
						sx={{ marginRight: 1, marginTop: 1 }}
					>
						Agregar
					</Button>
				) : (
					<div></div>
				)}
			</ButtonsContainer>
			<FiltersContainer showFilters={showFilters}>
				{groupsFilter && groups && (
					<SearchableSelect
						label="Grupos"
						name="group"
						onChange={handleChange}
						items={groups}
						value={filters?.group && +filters.group}
						idProperty={'id'}
						valueProperty={'name'}
						variant="outlined"
						size="small"
					/>
				)}

				{stateFilter && states && (
					<MenuSelect label="Estado" name="state" onChange={handleChange}>
						{states?.map((state: State) => (
							<MenuItem value={state.id} key={state.id}>
								<Pill color={state?.label_color as Color} text={state?.name} />
							</MenuItem>
						))}
					</MenuSelect>
				)}
				{dateFilter && <DateRangePicker onDateChange={onDateChange} />}
				{preferedZoneFilter && (
					<TextField
						value={filters.prefered_zone}
						onChange={(e) =>
							setFilters({ ...filters, prefered_zone: e.target.value })
						}
						variant="outlined"
						placeholder="Zona de interes..."
						id="prefered_zone"
						role="prefered_zone"
						size="small"
						sx={{ m: 1, minWidth: 150 }}
					/>
				)}
				{groupsTypeFilter && groupTypes && (
					<MenuSelect
						label="Tipo de grupo"
						name="group_type"
						onChange={handleChange}
					>
						{groupTypes?.map((groupType: GroupType) => (
							<MenuItem value={groupType.id} key={groupType.id}>
								<em>{groupType.name}</em>
							</MenuItem>
						))}
					</MenuSelect>
				)}
				{inactiveFilter && (
					<InteractiveToggle name="is_active" handleChange={handleChange} />
				)}
			</FiltersContainer>
		</MenuContainer>
	);
}
