import { useState } from 'react';
import { Alert, Box, Snackbar, TextField } from '@mui/material';
import ErrorText from '../../common/components/ErrorText';
import { LockOutlined } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useChangePassword } from '../../common/requests/change-password';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage } from '../../common/enums/messages';
import MamamHeader from '../../common/components/MamamH3';
import CustomSeparator from '../../common/components/BreadCrumbs';

const LoginInput = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'flex-end',
	justifyContent: 'center',
}));

const LoginForm = styled('form')(() => ({
	width: '35vw',
	height: '100%',
	display: 'flex',
	marginInline: 'auto',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: '20rem',
	'@media(max-width:700px)': {
		width: '100%',
		justifyContent: 'start',
		paddingTop: '5rem',
	},
}));

export default function ChangePassword() {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState<{
		oldPassword: string;
		newPassword: string;
	}>({ oldPassword: '', newPassword: '' });
	const [errors, setErrors] = useState({ errorMessage: '', serverError: '' });

	const changePassword = useChangePassword();
	const resetErrors = () => {
		setErrors({ errorMessage: '', serverError: '' });
	};

	const handleChange = (e: any): void => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSave = async () => {
		try {
			resetErrors();
			setLoading(true);
			const response = await changePassword(form);
			if (response) {
				setOpen(true);
			}
		} catch (error: any) {
			if (error.code === 'ERR_NETWORK') {
				setErrors({ ...errors, serverError: ErrorMessage.SERVER_ERROR });
			}
			if (error?.response?.status === 603) {
				setErrors({ ...errors, errorMessage: ErrorMessage.WRONG_PASSWORD });
			}
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		handleSave();
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<CustomSeparator
				crumbs={[{ name: 'Cambiar contraseña', path: '/cammbiar-contraseña' }]}
			/>

			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
					Contraseña cambiada correctamente
				</Alert>
			</Snackbar>
			<LoginForm noValidate autoComplete="off" onSubmit={handleSubmit}>
				<Box
					sx={{
						'& .MuiTextField-root': { m: 1, width: '25ch' },
					}}
				>
					<MamamHeader text="Cambiar contraseña" />

					<LoginInput>
						<LockOutlined sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
						<TextField
							label="Contraseña actual"
							variant="standard"
							name="oldPassword"
							type="password"
							value={form.oldPassword}
							onChange={handleChange}
							error={!!errors.errorMessage}
							disabled={loading}
						/>
					</LoginInput>
					<LoginInput>
						<LockOutlined sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
						<TextField
							label="Nueva Contraseña"
							variant="standard"
							name="newPassword"
							type="password"
							value={form.newPassword}
							onChange={handleChange}
							disabled={loading}
						/>
					</LoginInput>
				</Box>
				{(!!errors.errorMessage || !!errors.serverError) && (
					<ErrorText text={errors.errorMessage || errors.serverError} />
				)}
				<LoadingButton
					type="submit"
					loading={loading}
					variant="contained"
					disabled={form.newPassword.length < 5}
					style={{
						marginTop: '1rem',
						marginBottom: '5rem',
						cursor: 'pointer !important',
					}}
				>
					Cambiar contraseña
				</LoadingButton>
			</LoginForm>
		</div>
	);
}
