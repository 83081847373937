import { ReactNode, useEffect, useRef, useState } from 'react';
import ModalForm, { ModalFormProps } from '../ModalForm';
import { Box, Divider, MenuItem, TextField, Typography } from '@mui/material';
import { Group } from '../../../entities/Group';
import { ItemType } from '../../../enums/item-type';
import axios from 'axios';
import { GroupType } from '../../../entities/GroupType';
import { modalityArray } from '../../../enums/modality.enum';
import { useCreateGroup } from '../../../requests/create-group';
import { CoordinatorLabels } from '../../../entities/Coordinator';
import SearchableSelect from '../../SearchableSelect';
import { useUpdateGroup } from '../../../requests/update-group';
import { FormType } from '../../../enums/form-type.enum';

interface GroupFormModalProps
	extends Omit<ModalFormProps, 'children' | 'formType'> {
	children?: ReactNode;
	parentType: ItemType;
	isUpdate?: boolean;
	groupId?: number;
	handleSaveParent: Function;
}
export default function GroupFormModal({
	open,
	handleClose,
	title,
	handleSaveParent,
	parentType,
	groupId,
	isUpdate = false,
}: GroupFormModalProps) {
	const [form, setForm] = useState<Partial<Group>>({});
	const formRef = useRef(null);
	const [groupTypes, setGroupTypes] = useState<GroupType[]>([]);
	const [coordinators, setCoordinators] = useState<CoordinatorLabels[]>([]);
	const [errors, setErrors] = useState({ serverError: '' });
	const createGroup = useCreateGroup();
	const updateGroup = useUpdateGroup();

	useEffect(() => {
		if (isUpdate) {
			axios
				.request({ method: 'GET', url: `/groups/${groupId}` })
				.then((response) => {
					setForm(response.data);
				})
				.catch((error) => console.error(error));
		}
		axios
			.request({ method: 'GET', url: `/group_type` })
			.then((response) => {
				setGroupTypes(response.data.data);
			})
			.catch((error) => console.error(error));
		axios
			.request({ method: 'GET', url: `/coordinators/names` })
			.then((response) => {
				setCoordinators(response.data);
			})
			.catch((error) => console.error(error));

		return setForm({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (e: any): void => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSave = async () => {
		try {
			if (isUpdate && groupId) {
				await updateGroup(groupId, form);
			} else {
				await createGroup(form);
			}

			handleSaveParent();
			handleClose();
		} catch (error: any) {
			if (error.code === 'ERR_NETWORK')
				setErrors({ ...errors, serverError: 'true' });
		}
	};

	//modal form ref

	return (
		<ModalForm
			open={open}
			handleClose={handleClose}
			title={title}
			formRef={formRef}
			formType={isUpdate ? FormType.EDIT : FormType.ADD}
			hasServerError={!!errors?.serverError}
		>
			{(!isUpdate || Object.keys(form).length !== 0) && (
				<Box
					component="form"
					sx={{
						'& .MuiTextField-root, .MuiFormControl-root': {
							m: 1,
							marginInline: 3,
							width: '25ch',
						},
					}}
					autoComplete="off"
					ref={formRef}
					onSubmit={(e) => {
						e.preventDefault();
						handleSave();
					}}
				>
					<TextField
						id="group_type"
						name="group_type"
						select
						label="Tipo de grupo"
						defaultValue={form?.group_type?.id || form?.group_type || ''}
						SelectProps={{
							native: false,
						}}
						value={form?.group_type?.id || form?.group_type || ''}
						variant="standard"
						onChange={handleChange}
					>
						<MenuItem value="">---</MenuItem>

						{groupTypes?.map((groupType, index) => (
							<MenuItem value={groupType.id} key={index}>
								{groupType.name}
							</MenuItem>
						))}
					</TextField>

					<TextField
						id="modality"
						name="modality"
						select
						label="Modalidad"
						defaultValue={form.modality || ''}
						SelectProps={{
							native: false,
						}}
						value={form.modality || ''}
						variant="standard"
						onChange={handleChange}
						required
					>
						<MenuItem value="">---</MenuItem>
						{modalityArray?.map((modality, index) => (
							<MenuItem value={modality} key={index}>
								{modality}
							</MenuItem>
						))}
					</TextField>
					<Divider variant="middle" sx={{ marginBlock: 1.5 }} />

					<TextField
						id="country"
						name="country"
						label="País"
						placeholder="ej.: Argentina"
						variant="standard"
						value={form?.country}
						onChange={handleChange}
						required
					/>

					<TextField
						id="state"
						name="state"
						label="Provincia / Estado"
						placeholder="ej.: Buenos Aires"
						variant="standard"
						value={form?.state}
						onChange={handleChange}
					/>

					<TextField
						id="city"
						name="city"
						label="Ciudad"
						placeholder="ej.: San Isidro"
						variant="standard"
						value={form?.city}
						onChange={handleChange}
					/>

					<TextField
						id="zone"
						name="zone"
						label="Localidad"
						placeholder="ej.: Martinez"
						variant="standard"
						value={form?.zone}
						onChange={handleChange}
					/>
					<TextField
						id="address"
						name="address"
						label="Dirección"
						placeholder="ej.: Cochabamba 1614"
						variant="standard"
						value={form?.address}
						onChange={handleChange}
					/>
					<Divider variant="middle" sx={{ marginBlock: 1.5 }} />
					<TextField
						id="schedule"
						name="schedule"
						label="Horarios"
						placeholder="ej.: Jueves 16hs"
						variant="standard"
						value={form?.schedule}
						onChange={handleChange}
					/>
					<SearchableSelect
						label="Coordinadoras"
						name="coordinatorIds"
						items={coordinators}
						idProperty="id"
						valueProperty="name"
						defaultValue={form?.coordinatorIds}
						value={form?.coordinatorIds?.map((v) => Number(v))}
						onChange={handleChange}
						width="56ch !important"
						multiple
					/>
				</Box>
			)}
		</ModalForm>
	);
}
