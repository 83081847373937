import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
	Box,
	FormHelperText,
	styled,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { ReactNode, RefObject } from 'react';
import { FormType } from '../../../enums/form-type.enum';
import MamamHeader from '../../MamamH3';

export const StyledForm = styled(Box)(() => ({
	'& .MuiTextField-root, .MuiFormControl-root': {
		m: 1,
		marginInline: 3,
		width: '25ch',
	},
}));
export interface ModalFormProps {
	open: boolean;
	handleClose: () => void;
	title: string;
	formType: FormType;
	children: ReactNode;
	formRef?: RefObject<HTMLFormElement>;
	hasServerError?: boolean;
}

const getTextByType = (formType: FormType) => {
	switch (formType) {
		case FormType.EDIT:
			return 'Guardar';
		case FormType.ADD:
			return 'Agregar';

		case FormType.DELETE:
		case FormType.RESTORE:
			return 'Ok';
		case FormType.DERIVATE:
			return 'Derivar';
		default:
			return 'Agregar';
	}
};

export default function ModalForm({
	open,
	handleClose,
	title,
	children,
	formRef,
	formType,
	hasServerError,
}: ModalFormProps) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const handleOk = () => {
		formRef?.current?.requestSubmit();
	};
	const handleKeyPress = (event: React.KeyboardEvent) => {
		console.log(event.key);
		if (event.key === 'Enter') {
			event.preventDefault(); // Previene cualquier acción por defecto
			handleOk();
		}
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				fullScreen={fullScreen}
				onKeyDown={handleKeyPress}
			>
				<DialogTitle sx={{ paddingBlock: '10px' }}>
					<MamamHeader text={title} marginBlock="0.6rem" fontSize="1.3rem" />
				</DialogTitle>
				<DialogContent>{children}</DialogContent>
				{hasServerError && (
					<FormHelperText error={hasServerError} sx={{ textAlign: 'center' }}>
						Ups! Ha ocurrido un error en el servidor, intente nuveamente mas
						tarde.
					</FormHelperText>
				)}
				<DialogActions>
					<Button onClick={handleClose} variant="outlined" color="error">
						Cancelar
					</Button>
					<Button onClick={handleOk} variant="contained">
						{getTextByType(formType)}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
