import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import axios from 'axios';

import Table from '../../common/components/Table';
import { PAGE_SIZE } from '../../common/constants/page-size';
import { BaseResponse } from '../../common/interfaces/base-response';
import RowOptionsButton from '../../common/components/RowOptionsButton';
import { ItemType } from '../../common/enums/item-type';
import { CoordinatorList } from '../../common/entities/Coordinator';
import Pill from '../../common/components/Pill';
import { Color } from '../../common/enums/pill-colors';
import CoordinatorFormModal from '../../common/components/FormModals/CoordinatorFormModal';
import CustomSeparator from '../../common/components/BreadCrumbs';
import MamamHeader from '../../common/components/MamamH3';

export default function Coordinators() {
	const [page, setPage] = useState(0);
	const [searchOptions, setSearchOptions] = useState('');
	const [sendReq, setSendReq] = useState(false);
	const [open, setOpen] = useState(false);
	const [coordinators, setCoordinators] =
		useState<BaseResponse<CoordinatorList>>();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		axios
			.request({
				method: 'GET',
				url: `/coordinators/list?page=${page}&limit=${PAGE_SIZE}${searchOptions}`,
			})
			.then((response) => {
				setCoordinators(response.data);
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	}, [page, searchOptions, sendReq]);

	const applyFilters = (filters: string) => {
		setSearchOptions(filters);
		setSendReq(!sendReq);
	};

	const handlePageChange = (pageNumber: number) => {
		setPage(pageNumber);
		setSendReq(!sendReq);
	};

	const handleClickAdd = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		setSendReq(!sendReq);
	};

	const rows: GridRowsProp =
		coordinators?.data?.map((row) => ({ ...row, options: row.id })) || [];

	const columns: GridColDef[] = [
		{
			field: 'options',
			headerName: `${coordinators?.count || 0}`,
			width: 100,
			cellClassName: 'table-options',
			headerClassName: 'table-options-header',
			renderCell(params) {
				return (
					<RowOptionsButton
						itemId={params.value}
						handleSave={handleSave}
						tabIndex={params.tabIndex}
						itemType={ItemType.COORDINATOR}
						is_active={params.row.is_active}
					/>
				);
			},
		},
		{ field: 'name', headerName: 'Nombre', width: 230 },
		{ field: 'email', headerName: 'Email', width: 250 },

		{
			field: 'groups',
			headerName: 'Grupo/s',
			width: 320,
			renderCell(params) {
				const pills = params.value.map((group: any, i: number) => {
					return (
						<Link to={`/grupos/${group.id}`} key={i}>
							<Pill text={group.name} color={Color.Blue} />
						</Link>
					);
				});
				return (
					<Box
						sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBlock: 1 }}
					>
						{pills}
					</Box>
				);
			},
		},
	];

	return (
		<div>
			{open && (
				<CoordinatorFormModal
					open={open}
					handleClose={handleClose}
					handleSaveParent={handleSave}
					title="Inscripción coordinadora"
					parentType={ItemType.COORDINATOR}
				/>
			)}
			<CustomSeparator
				crumbs={[{ name: 'Coordinadoras', path: '/coordinadoras' }]}
			/>
			<MamamHeader text="Coordinadoras" marginBlock="1.5rem" />
			<Table
				rows={rows}
				columns={columns}
				loading={loading || !coordinators?.data}
				onPageChange={handlePageChange}
				rowCount={coordinators?.count}
				applyFilters={applyFilters}
				handleClickAdd={handleClickAdd}
				itemType={ItemType.COORDINATOR}
			></Table>
		</div>
	);
}
