import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { NavigateFunction, useNavigate } from 'react-router';

function handleClick(
	event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
	navigate: NavigateFunction,
	path: string
) {
	event.preventDefault();
	navigate(path);
}

interface BreadCrumb {
	name: string;
	path: string;
}

const CustomSeparator = React.memo(function CustomSeparator({
	crumbs,
}: {
	crumbs: BreadCrumb[];
}) {
	const navigate = useNavigate();

	const breadcrumbs = crumbs?.map((bc, i) => {
		if (crumbs.length - 1 === i) {
			return (
				<Typography key={i} color="text.primary" sx={{ cursor: 'default' }}>
					{bc.name}
				</Typography>
			);
		}

		return (
			<Link
				underline="hover"
				key={i}
				color="inherit"
				onClick={(e) => handleClick(e, navigate, bc.path)}
				sx={{ cursor: 'pointer' }}
			>
				{bc.name}
			</Link>
		);
	});

	breadcrumbs.unshift(
		<Link
			underline="hover"
			key={100}
			color="inherit"
			onClick={(e) => handleClick(e, navigate, '/')}
			sx={{ cursor: 'pointer' }}
		>
			Home
		</Link>
	);

	return (
		<Breadcrumbs
			separator="›"
			aria-label="breadcrumb"
			sx={{ marginBlock: '0.5rem' }}
		>
			{breadcrumbs}
		</Breadcrumbs>
	);
});

export default CustomSeparator;
