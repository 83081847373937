import { createContext, ReactNode, useContext, useState } from 'react';
import { CurrentUser } from '../../interfaces/current-user.interface';

export const UserContext = createContext<CurrentUser | null>(null);
const UserUpdateContext = createContext<Function>(() => {});

export const useUser = () => {
	return useContext(UserContext);
};

export const useUserUpdate = () => {
	return useContext(UserUpdateContext);
};

export default function Context({ children }: { children: ReactNode }) {
	const [user, setUser] = useState<CurrentUser | null>(null);

	function saveUser(u: CurrentUser) {
		setUser(u);
	}

	return (
		<UserContext.Provider value={user}>
			<UserUpdateContext.Provider value={saveUser}>
				{children}
			</UserUpdateContext.Provider>
		</UserContext.Provider>
	);
}
