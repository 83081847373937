import { ReactNode, useEffect, useRef, useState } from 'react';
import ModalForm, { ModalFormProps } from '../ModalForm';
import { Box, TextField } from '@mui/material';
import axios from 'axios';
import { ItemType } from '../../../enums/item-type';
import { useCreateGroupType } from '../../../requests/create-group-type';
import { useUpdateGroupType } from '../../../requests/update-group-type';
import { GroupType } from '../../../entities/GroupType';
import { FormType } from '../../../enums/form-type.enum';

interface GroupTypeFormModalProps
	extends Omit<ModalFormProps, 'children' | 'formType'> {
	children?: ReactNode;
	parentType: ItemType;
	isUpdate?: boolean;
	groupTypeId?: number;
	handleSaveParent: Function;
}
export default function GroupTypeFormModal({
	open,
	handleClose,
	title,
	handleSaveParent,
	parentType,
	groupTypeId,
	isUpdate = false,
}: GroupTypeFormModalProps) {
	const [form, setForm] = useState<Partial<GroupType>>({});
	const formRef = useRef(null);
	const createGroupType = useCreateGroupType();
	const updateGroupType = useUpdateGroupType();
	const [errors, setErrors] = useState({ serverError: '' });

	useEffect(() => {
		if (isUpdate) {
			axios
				.request({ method: 'GET', url: `/group_type/${groupTypeId}` })
				.then((response) => {
					setForm(response.data);
				})
				.catch((error) => console.error(error));
		}
		return setForm({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (e: any): void => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSave = async () => {
		try {
			if (isUpdate && groupTypeId) {
				await updateGroupType(groupTypeId, form);
			} else {
				await createGroupType(form);
			}

			handleSaveParent();
			handleClose();
		} catch (error: any) {
			if (error.code === 'ERR_NETWORK')
				setErrors({ ...errors, serverError: 'true' });
		}
	};

	//modal form ref

	return (
		<ModalForm
			open={open}
			handleClose={handleClose}
			title={title}
			formRef={formRef}
			formType={isUpdate ? FormType.EDIT : FormType.ADD}
			hasServerError={!!errors?.serverError}
		>
			{(!isUpdate || Object.keys(form).length !== 0) && (
				<Box
					component="form"
					sx={{
						'& .MuiTextField-root, .MuiFormControl-root': {
							m: 1,
							marginInline: 3,
							width: '25ch',
						},
					}}
					autoComplete="off"
					ref={formRef}
					onSubmit={(e) => {
						e.preventDefault();
						handleSave();
					}}
				>
					<TextField
						// required
						id="name"
						name="name"
						label="Nombre"
						placeholder="ej.: Crianza"
						variant="standard"
						value={form.name}
						onChange={handleChange}
					/>
				</Box>
			)}
		</ModalForm>
	);
}
