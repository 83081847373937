import { Checkbox, FormControlLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';

interface InteractiveToggleProps {
	handleChange: (event: any, checked: boolean) => void;
	name?: string;
}

export default function InteractiveToggle({
	handleChange,
	name,
}: InteractiveToggleProps) {
	const [showInactives, setShowInactives] = useState(false);
	return (
		<FormControlLabel
			label={showInactives ? 'No ver inactivas' : 'Ver inactivas'}
			sx={{ userSelect: 'none', marginInline: 1 }}
			control={
				<Checkbox
					name={name}
					icon={<Visibility />}
					checkedIcon={<VisibilityOff />}
					onChange={(e, checked) => {
						setShowInactives(checked);
						handleChange(
							{ ...e, target: { name: e.target.name, value: !checked } },
							checked
						);
					}}
				/>
			}
		/>
	);
}
