import axios, { AxiosRequestConfig } from 'axios';
import { useToken } from '../contexts/tokenContext';
import { Member } from '../entities/Member';
import { ItemType } from '../enums/item-type';

export const useDeleteItem = () => {
	const token = useToken();
	return async (itemId: number, itemType: ItemType): Promise<Member | null> => {
		try {
			if (!token?.accessToken) {
				return null;
			}

			const params: AxiosRequestConfig = {
				method: 'DELETE',
				url: `/${itemType}/${itemId}`,
				withCredentials: true,
			};
			const result = await axios.request(params);
			return result.data;
		} catch (error) {
			throw error;
		}
	};
};
