import axios, { AxiosRequestConfig } from 'axios';
import { useToken } from '../contexts/tokenContext';
import { Group } from '../entities/Group';

export const useUpdateGroup = () => {
	const token = useToken();
	return async (groupId: number, form: any): Promise<Group | null> => {
		try {
			if (!token?.accessToken) {
				return null;
			}

			delete form.coordinators;
			delete form.name;

			const params: AxiosRequestConfig = {
				method: 'PATCH',
				url: `/groups/${groupId}`,
				data: form,
				withCredentials: true,
			};
			const result = await axios.request(params);
			return result.data;
		} catch (error) {
			throw error;
		}
	};
};
