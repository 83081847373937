import React from 'react';
import { Color } from '../../enums/pill-colors';

interface PillProps {
	color: Color;
	text: string | undefined;
	tabIndex?: number;
}

const getColorStyles = (colorName: string) => {
	switch (colorName) {
		case Color.Red:
			return {
				color: '#cf1322',
				background: '#fff1f0',
				borderColor: '#ffa39e',
			};
		case Color.Blue:
			return {
				color: '#0050b3',
				background: '#f0f5ff',
				borderColor: '#adc6ff',
			};
		case Color.Green:
			return {
				color: '#389e0d',
				background: '#f6ffed',
				borderColor: '#b7eb8f',
			};
		case Color.Lime:
			return {
				color: '#7cb30',
				background: '#fcffe6',
				borderColor: '#eaff8f',
			};
		case Color.Gold:
			return {
				color: '#d48806',
				background: '#fffbe6',
				borderColor: '#ffe58f',
			};
		case Color.Orange:
			return {
				color: '#d46b08',
				background: '#fff7e6',
				borderColor: '#ffd591',
			};
		case Color.Purple:
			return {
				color: '#531dab',
				background: '#f9f0ff',
				borderColor: '#d3adf7',
			};
		case Color.Pink:
			return {
				color: '#eb2f96',
				background: '#fff0f6',
				borderColor: '#ffadd2',
			};
		case Color.Cyan:
			return {
				color: '#08979c',
				background: '#e6fffb',
				borderColor: '#87e8de',
			};
		case Color.Magenta:
			return {
				color: '#c41d7f',
				background: '#fff0f6',
				borderColor: '#ffadd2',
			};
		case Color.Volcano:
			return {
				color: '#d4380d',
				background: '#fff2e8',
				borderColor: '#ffbb96',
			};
		case Color.GeekBlue:
			return {
				color: '#1d39c4',
				background: '#f0f5ff',
				borderColor: '#adc6ff',
			};
		default:
			return {
				color: '#1d39c4',
				background: '#f0f5ff',
				borderColor: '#adc6ff',
			};
	}
};
export default function Pill({ color, text, tabIndex }: PillProps) {
	const pillStyle: React.CSSProperties = {
		display: 'inline-block',
		borderRadius: '4px',
		paddingInline: '7px',
		border: '1px solid',
		lineHeight: '20px',
		width: 'fit-content',
		fontSize: '12px',

		...getColorStyles(color),
	};

	return (
		<span style={pillStyle} tabIndex={tabIndex}>
			{text}
		</span>
	);
}
