import { Actions } from '../enums/actions.enum';
import { ItemType } from '../enums/item-type';
import { ErrorMessage } from '../enums/messages';
import { Color } from '../enums/pill-colors';
import { UserRole } from '../enums/user-role.enum';
import { CurrentUser } from '../interfaces/current-user.interface';

export const dateFormater = (dateInput: Date | string) => {
	if (typeof dateInput === 'string') {
		dateInput = new Date(`${dateInput}T00:00:00`);
	}
	// Add 1 to the month because it is 0-indexed
	return `${dateInput.getDate()}/${
		dateInput.getMonth() + 1
	}/${dateInput.getFullYear()}`;
};

export const dateTimeFormater = (inputDate: string) => {
	// Obtener los componentes de la fecha y hora+
	const date = new Date(inputDate);
	let day = String(date.getDate());
	let month = String(date.getMonth() + 1); // Los meses en JavaScript se indexan desde 0
	let year = String(date.getFullYear());
	let hour = String(date.getHours());
	let minutes = String(date.getMinutes());

	// Agregar ceros iniciales si es necesario
	if (+day < 10) {
		day = '0' + day;
	}
	if (+month < 10) {
		month = '0' + month;
	}
	if (+hour < 10) {
		hour = '0' + hour;
	}
	if (+minutes < 10) {
		minutes = '0' + minutes;
	}

	return `${day}/${month}/${year} - ${hour}:${minutes}`;
};

export const colorNameSpanish = (color: Color) => {
	const colorNames = {
		[Color.Red]: 'Rojo',
		[Color.Blue]: 'Azul',
		[Color.Green]: 'Verde',
		[Color.Lime]: 'Lima',
		[Color.Gold]: 'Oro',
		[Color.Orange]: 'Naranja',
		[Color.Purple]: 'Morado',
		[Color.Pink]: 'Rosa',
		[Color.Cyan]: 'Cian',
		[Color.Magenta]: 'Magenta',
		[Color.Volcano]: 'Volcán',
		[Color.GeekBlue]: 'Azul Geek',
	};

	return colorNames[color];
};

export const actionToColor = (action: Actions): Color => {
	switch (action) {
		case Actions.DERIVATED:
			return Color.Green;

		case Actions.INSERTED:
			return Color.Blue;
		case Actions.RESTORED:
			return Color.Green;

		case Actions.STATE_CHANGE:
			return Color.Blue;

		case Actions.UNSUSCRIBED:
			return Color.Red;
	}
};

export const itemTypeToUrlName = (itemType: ItemType): string => {
	switch (itemType) {
		case ItemType.GROUP:
			return 'grupos';

		case ItemType.MEMBER:
		case ItemType.GROUP_CLIENT:
		case ItemType.INTERESTED:
			return 'miembros';

		case ItemType.COORDINATOR:
			return 'coordinadoras';

		default:
			return '';
	}
};

export const itemTypeToName = (itemType: ItemType): string => {
	switch (itemType) {
		case ItemType.GROUP:
			return 'grupo';

		case ItemType.MEMBER:
		case ItemType.GROUP_CLIENT:
			return 'miembro';
		case ItemType.INTERESTED:
			return 'interesada';

		case ItemType.COORDINATOR:
			return 'coordinadora';

		case ItemType.STATE:
			return 'estado';
		case ItemType.GROUP_TYPE:
			return 'tipo de grupo';
		default:
			return '';
	}
};

export const itemTypeToRealType = (itemType: ItemType): ItemType => {
	switch (itemType) {
		case ItemType.GROUP_CLIENT:
			return ItemType.MEMBER;
		default:
			return itemType;
	}
};

export const isAdmin = (user: CurrentUser | null): boolean => {
	if (!user) return false;
	return user?.role === UserRole.ADMIN;
};

export const getErrorMessage = (message: string): ErrorMessage => {
	const errorMessageKey = message as keyof typeof ErrorMessage;
	return ErrorMessage[errorMessageKey];
};
