import axios, { AxiosRequestConfig } from 'axios';
import { useToken } from '../contexts/tokenContext';
import { Member } from '../entities/Member';

export const useUpdateClient = () => {
	const token = useToken();
	return async (clientId: number, form: any): Promise<Member | null> => {
		try {
			if (!token?.accessToken) {
				return null;
			}

			delete form.id;
			delete form.created_at;
			delete form.modified_at;
			delete form.state;

			const params: AxiosRequestConfig = {
				method: 'PATCH',
				url: `/clients/${clientId}`,
				data: form,
				withCredentials: true,
			};
			const result = await axios.request(params);
			return result.data;
		} catch (error) {
			throw error;
		}
	};
};
