import { useNavigate } from 'react-router';
import { useTokenUpdate } from '../contexts/tokenContext';
import axios, { AxiosRequestConfig } from 'axios';

export const useCloseSession = () => {
	const setToken = useTokenUpdate();
	const navigate = useNavigate();
	return async () => {
		//remove accessToken
		setToken(null);
		//remove refreshToken cookie
		const params: AxiosRequestConfig = {
			method: 'POST',
			url: '/auth/logout',
			withCredentials: true,
		};
		await axios.request(params);
		navigate('/');
	};
};
