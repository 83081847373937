import { useNavigate, useParams } from 'react-router';
import { useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { Alert, Box, Snackbar, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { LockOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';
import LogoMamam from '../../common/assets/logo512.png';
import ErrorText from '../../common/components/ErrorText';
import { ErrorMessage } from '../../common/enums/messages';
import { Link } from 'react-router-dom';
import MamamHeader from '../../common/components/MamamH3';
import Portada from '../../common/assets/Portada.jpg';

const LoginContainer = styled('div')(() => ({
	display: 'flex',
	height: '100vh',
	overflow: 'hidden',
}));

const LoginInput = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'flex-end',
	justifyContent: 'center',
}));

const LogoImg = styled('img')(() => ({
	marginBottom: '1rem',
	width: '175px',
	height: '96px',
}));
const ForgotLink = styled('button')(() => ({
	background: 'none',
	border: 'none',
	padding: '0',
	font: 'inherit',
	cursor: 'pointer',
	outline: 'inherit',
	fontSize: '.9rem',
	color: '#424040',
	marginTop: '2rem',
	'&:hover': {
		color: '#4b4b48',
	},
}));

const SideImage = styled('div')(() => ({
	width: '65vw',
	maxWidth: '100%',
	maxHeight: '100%',
	height: '100vh',
	'@media(max-width:700px)': {
		display: 'none',
	},
	backgroundImage: `url(${Portada})`,
}));

const LoginForm = styled('form')(() => ({
	width: '35vw',
	height: '100%',
	backgroundColor: '#f3f2ee',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: '20rem',
	'@media(max-width:700px)': {
		width: '100%',
		justifyContent: 'start',
		paddingTop: '5rem',
	},
}));

export default function RestorePassword() {
	const [form, setForm] = useState({ email: '', password: '' });
	const [errors, setErrors] = useState({ expiredError: '', serverError: '' });
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();

	const { hash } = useParams();

	const resetErrors = () => {
		setErrors({ expiredError: '', serverError: '' });
	};

	const sendLogin = async () => {
		try {
			setLoading(true);

			const params: AxiosRequestConfig = {
				method: 'POST',
				url: '/password/recover',
				data: { hash, newPassword: form.password },
			};

			await axios.request(params);
			setOpen(true);
			setTimeout(() => navigate('/'), 3000);
		} catch (error: any) {
			console.log(error);
			if (error.code === 'ERR_NETWORK') {
				setErrors({ ...errors, serverError: ErrorMessage.SERVER_ERROR });
			}
			if (error?.response?.status === 601) {
				setErrors({ ...errors, expiredError: ErrorMessage.EXPIRED_ERROR });
			}
			if (error?.response?.status === 600) {
				setErrors({
					...errors,
					expiredError: ErrorMessage.REUTILIZED_HASH_ERROR,
				});
			}
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [event.target.name]: event.target.value });
		resetErrors();
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		sendLogin();
		resetErrors();
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<LoginContainer>
			<Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
					Contraseña cambiada con éxito. Será redireccionado en unos segundos.
				</Alert>
			</Snackbar>
			<SideImage></SideImage>

			<LoginForm noValidate autoComplete="off" onSubmit={handleSubmit}>
				<Link to="/">
					<LogoImg src={LogoMamam} alt="logo-mamam" />
				</Link>
				<Box
					sx={{
						'& > :not(style)': { m: 2.5 },
					}}
				>
					<MamamHeader text="NUEVA CONTRASEÑA" />
					<LoginInput>
						<LockOutlined sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
						<TextField
							label="Nueva contraseña"
							variant="standard"
							name="password"
							type="password"
							value={form.password}
							onChange={handleChange}
							error={!!errors.expiredError}
							disabled={loading}
						/>
					</LoginInput>
				</Box>

				{(!!errors.expiredError || !!errors.serverError) && (
					<ErrorText text={errors.expiredError || errors.serverError} />
				)}

				{!!errors.expiredError && (
					<Link to="/">
						<ForgotLink>Volver al inicio</ForgotLink>
					</Link>
				)}
				<LoadingButton
					type="submit"
					loading={loading}
					disabled={form.password.length < 5}
					variant="contained"
					loadingIndicator={'Enviando...'}
					style={{
						marginTop: '1rem',
						marginBottom: '5rem',
						cursor: 'pointer !important',
					}}
				>
					Cambiar contraseña
				</LoadingButton>
			</LoginForm>
		</LoginContainer>
	);
}
