import { useLocation, useNavigate } from 'react-router';
import React, { useCallback, useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { Alert, Box, Snackbar, TextField, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { AccountCircle, LockOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';
import LogoMamam from '../../common/assets/logo512.png';
import LogoEntero from '../../common/assets/logo-entero.png';

import Portada from '../../common/assets/Portada.jpg';
import { useTokenUpdate } from '../../common/contexts/tokenContext';
import { AuthToken } from '../../common/interfaces/auth-token.interface';
import ErrorText from '../../common/components/ErrorText';
import { ErrorMessage } from '../../common/enums/messages';
import MamamHeader from '../../common/components/MamamH3';

const WINDOW_RESIZE = 1000;
const LoginContainer = styled('div')(() => ({
	display: 'flex',
	height: '100vh',
	overflow: 'hidden',
}));

const LoginInput = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'flex-end',
	justifyContent: 'center',
}));

const LogoImg = styled('img')(() => ({
	marginBottom: '1rem',
	height: '130px',
	'@media(max-width:1000px)': {
		width: '175px',
		height: '96px',
	},
}));
const ForgotLink = styled('button')(() => ({
	background: 'none',
	border: 'none',
	padding: '0',
	font: 'inherit',
	cursor: 'pointer',
	outline: 'inherit',
	fontSize: '.9rem',
	color: '#424040',
	marginTop: '2rem',
	'&:hover': {
		color: '#4b4b48',
	},
}));

const SideImage = styled('div')(() => ({
	width: '65vw',
	maxWidth: '100%',
	maxHeight: '100%',
	height: '100vh',
	'@media(max-width:700px)': {
		display: 'none',
	},
	backgroundImage: `url(${Portada})`,
}));

const LoginForm = styled('form')(() => ({
	width: '35vw',
	height: '100%',
	backgroundColor: '#f3f2ee',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: '20rem',
	'@media(max-width:700px)': {
		width: '100%',
		justifyContent: 'start',
		paddingTop: '5rem',
	},
}));

export default function Login() {
	const [form, setForm] = useState({ email: '', password: '' });
	const [errors, setErrors] = useState({ authError: '', serverError: '' });
	const [forgotPassword, setForgotPassword] = useState(false);
	const [response, setResponse] = useState<AuthToken | null>(null);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [windowSize, setWindowSize] = useState([
		window.innerWidth,
		window.innerHeight,
	]);
	const theme = useTheme();
	const { secondary } = theme.palette;
	const setToken = useTokenUpdate();
	const navigate = useNavigate();
	const location = useLocation();

	const resetErrors = () => {
		setErrors({ authError: '', serverError: '' });
	};

	const sendLogin = async () => {
		try {
			setLoading(true);

			const params: AxiosRequestConfig = {
				method: 'POST',
				url: forgotPassword ? '/password/forgot' : '/auth/login',
				data: forgotPassword ? { email: form.email } : form,
				withCredentials: true,
			};
			const result = await axios.request(params);

			if (forgotPassword) {
				setOpen(true);
			} else {
				setResponse(result.data);
				axios.defaults.headers.common[
					'Authorization'
				] = `Bearer ${result.data.accessToken}`;
			}
		} catch (error: any) {
			console.log(error);
			if (error.response.status === 604) {
				return setErrors({
					...errors,
					authError: ErrorMessage.DEACTIVATED_USER,
				});
			} else if (error.code === 'ERR_NETWORK') {
				return setErrors({ ...errors, serverError: ErrorMessage.SERVER_ERROR });
			} else {
				setErrors({
					...errors,
					authError: forgotPassword
						? ErrorMessage.EMAIL_DOESNT_EXIST
						: ErrorMessage.INVALID_USER,
				});
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize([window.innerWidth, window.innerHeight]);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	useEffect(() => {
		if (response) {
			setToken(response);

			if (location.state?.from) navigate(location.state.from);
		}

		//TODO catchear errores de servidor
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [response]);

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setForm((prevForm) => ({
				...prevForm,
				[event.target.name]: event.target.value,
			}));
			resetErrors();
		},
		[]
	);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		sendLogin();
		resetErrors();
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<LoginContainer>
			<Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
					Mail enviado, puede tardar un momento, recuerda revisar la carpeta de
					"spam"
				</Alert>
			</Snackbar>
			<SideImage />

			<LoginForm noValidate autoComplete="off" onSubmit={handleSubmit}>
				<LogoImg
					src={windowSize[0] < WINDOW_RESIZE ? LogoMamam : LogoEntero}
					alt="logo-mamam"
				/>
				<Box
					sx={{
						'& > :not(style)': { m: 2.5 },
					}}
				>
					<MamamHeader
						text={forgotPassword ? 'RECUPERAR CONTRASEÑA' : 'INICIAR SESIÓN'}
					/>

					<LoginInput>
						<AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
						<TextField
							label="Email"
							variant="standard"
							name="email"
							value={form.email}
							onChange={handleChange}
							error={!!errors.authError}
							disabled={loading}
						/>
					</LoginInput>
					{!forgotPassword && (
						<LoginInput>
							<LockOutlined sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
							<TextField
								label="Contraseña"
								variant="standard"
								name="password"
								type="password"
								value={form.password}
								onChange={handleChange}
								error={!!errors.authError}
								disabled={loading}
							/>
						</LoginInput>
					)}
				</Box>

				{(!!errors.authError || !!errors.serverError) && (
					<ErrorText text={errors.authError || errors.serverError} />
				)}
				<ForgotLink
					onClick={() => setForgotPassword(!forgotPassword)}
					type="button"
				>
					{forgotPassword ? 'Volver' : '¿Olvidaste la contraseña?'}
				</ForgotLink>
				<LoadingButton
					type="submit"
					loading={loading}
					variant="contained"
					loadingIndicator={forgotPassword ? 'Enviando...' : 'Entrando...'}
					style={{
						backgroundColor: secondary.light,
						marginTop: '1rem',
						marginBottom: '5rem',
						cursor: 'pointer !important',
					}}
				>
					{forgotPassword ? 'Enviar mail' : 'Iniciar sesión'}
				</LoadingButton>
			</LoginForm>
		</LoginContainer>
	);
}
