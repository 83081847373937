import { FormHelperText } from '@mui/material';

interface ErrorTextParams {
	text: string;
}
export default function ErrorText({ text }: ErrorTextParams) {
	return (
		<FormHelperText
			error
			sx={{
				display: 'flex',
				width: '80%',
				justifyContent: 'center',
			}}
		>
			{text}
		</FormHelperText>
	);
}
