import styled from '@emotion/styled';
import {
	Box,
	FormControl,
	InputAdornment,
	InputLabel,
	ListSubheader,
	MenuItem,
	MenuProps,
	Select,
	SelectProps,
	TextField,
} from '@mui/material';
import { useMemo, useState } from 'react';
import Pill from '../Pill';
import { Color } from '../../enums/pill-colors';
import { GridSearchIcon } from '@mui/x-data-grid';

const SearchableSelectLabel = styled(InputLabel)`
	-webkit-transform: translate(0, 16px) scale(1);
	transform: translate(0px, 16px) scale(1);
	&[data-shrink='true'] {
		-webkit-transform: translate(0, -1.5px) scale(0.75);
		transform: translate(0px, -1.5px) scale(0.75);
	}
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuProps: Partial<MenuProps> = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 'fit-content',
			overflowX: 'auto',
			position: 'relative',
		},
	},
	autoFocus: false,
};

interface SearchableSelectProps<T> {
	label: string;
	value?: string[] | number[] | string | number;
	defaultValue?: string[] | number[] | string | number;
	name: string;
	onChange: any;
	items: T[];
	idProperty: keyof T;
	valueProperty: keyof T;
	width?: string;
	size?: string;
}

const containsText = (text: string, searchText: string) =>
	text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
export default function SearchableSelect<T>({
	label,
	onChange,
	value = [],
	defaultValue = [],
	name,
	items,
	idProperty,
	valueProperty,
	width,
	variant = 'standard',
	multiple,
	size,
}: SearchableSelectProps<T> & SelectProps) {
	const [inputValue, setInputValue] = useState('');

	const findItemValue = (id: number | string): string | undefined => {
		const item = items?.find((item) => item[idProperty] === id);
		return item && `${item[valueProperty]}`;
	};

	const getItemValueTyped = (item: T) => {
		let itemValue;
		if (typeof item[idProperty] === 'number') {
			itemValue = Number(item[idProperty]);
		} else if (typeof item[idProperty] === 'string') {
			itemValue = `${item[idProperty]}`;
		}
		return itemValue;
	};

	const displayedOptions = useMemo(
		() =>
			inputValue === ''
				? items
				: items.filter((option) =>
						containsText(option[valueProperty] as string, inputValue)
				  ),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[inputValue, items]
	);

	return (
		<FormControl sx={{ m: 1, width: width || '25ch' }} size={size}>
			{variant === 'standard' ? (
				<SearchableSelectLabel id={`${label}-label`}>
					{label}
				</SearchableSelectLabel>
			) : (
				<InputLabel id={`${label}-label`}>{label}</InputLabel>
			)}
			<Select
				labelId={`${label}-label`}
				label={label}
				id={name}
				name={name}
				multiple={multiple}
				defaultValue={defaultValue || []}
				value={value || []}
				variant={variant}
				onChange={onChange}
				onClose={() => setInputValue('')}
				MenuProps={menuProps}
				renderValue={(selected) => {
					return multiple ? (
						<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
							{Array.isArray(selected) &&
								selected.map((idValue) => {
									const value = findItemValue(idValue);
									return (
										value && (
											<Pill
												key={idValue}
												text={findItemValue(idValue)}
												color={Color.Blue}
											/>
										)
									);
								})}
						</Box>
					) : (
						<span> {findItemValue(+selected)}</span>
					);
				}}
			>
				<ListSubheader
					sx={{
						position: 'sticky',
						left: 0,
						zIndex: 100, // Puede ajustarse según sea necesario
					}}
				>
					<TextField
						size="small"
						// Autofocus on textfield

						autoFocus
						placeholder="Buscar..."
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<GridSearchIcon />
								</InputAdornment>
							),
						}}
						onChange={(e) => setInputValue(e.target.value)}
						onKeyDown={(e) => {
							if (e.key !== 'Escape') {
								// Prevents autoselecting item while typing (default Select behaviour)
								e.stopPropagation();
							}
						}}
					/>
				</ListSubheader>
				{!multiple && <MenuItem value="">---</MenuItem>}
				{displayedOptions?.map((item, i) => {
					return (
						<MenuItem value={getItemValueTyped(item)} key={i}>
							{`${item[valueProperty]}`}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}
