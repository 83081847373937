import axios, { AxiosRequestConfig } from 'axios';
import { useToken } from '../contexts/tokenContext';
import { Member } from '../entities/Member';

export const useCreateClient = () => {
	const token = useToken();
	return async (form: any): Promise<Member | null> => {
		try {
			if (!token?.accessToken) {
				return null;
			}

			const params: AxiosRequestConfig = {
				method: 'POST',
				url: '/clients',
				data: form,
				withCredentials: true,
			};
			const result = await axios.request(params);
			return result.data;
		} catch (error) {
			throw error;
		}
	};
};
