import { useUser } from '../../contexts/userContext';
import { isAdmin } from '../../utils';
import { Navigate, useParams } from 'react-router';

export default function OwnsGroup({ children }: { children: JSX.Element }) {
	const user = useUser();
	const { id } = useParams();

	const ownsGroup = user?.groups.some((g) => Number(g.id) === Number(id));

	return isAdmin(user) || ownsGroup ? children : <Navigate to="/" replace />;
}
