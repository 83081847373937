import { Box, styled } from '@mui/material';
import { DataGrid, DataGridProps, GridRowProps } from '@mui/x-data-grid';
import { PAGE_SIZE } from '../../constants/page-size';
import MenuTable from '../MenuTable';
import { ItemType } from '../../enums/item-type';

const StyledDataGrid = styled(DataGrid)`
	& .MuiDataGrid-renderingZone {
		max-height: none !important;
	}

	& .MuiDataGrid-cell {
		line-height: unset !important;
		max-height: none !important;
		white-space: normal;
	}

	& .MuiDataGrid-row {
		max-height: none !important;
	}
`;

export default function Table({
	rows,
	columns,
	loading,
	onPageChange,
	rowCount,
	applyFilters,
	handleClickAdd,
	itemType,
}: TableProps) {
	rows = rows.map((row: GridRowProps) => ({
		...row,
	}));
	columns = columns.map((column) => ({
		...column,
		sortable: false,
		valueGetter: (params) => (params.value ? params.value : '---'),
	}));

	const getProps = (itemType: ItemType) => {
		switch (itemType) {
			case ItemType.INTERESTED:
				return {
					searchbar: true,
					dateFilter: true,
					inactiveFilter: true,
				};
			case ItemType.GROUP_LIST:
				return {
					searchbar: true,
					groupsTypeFilter: true,
					noAdd: true,
				};

			case ItemType.MEMBER:
				return {
					searchbar: true,
					groupsFilter: true,
					stateFilter: true,
					dateFilter: true,
					inactiveFilter: true,
					preferedZoneFilter: true,
				};
			case ItemType.GROUP_CLIENT:
				return {
					searchbar: true,
					stateFilter: true,
					dateFilter: true,
					inactiveFilter: true,
				};

			case ItemType.GROUP:
				return {
					searchbar: true,
					groupsTypeFilter: true,
					inactiveFilter: true,
				};
			case ItemType.COORDINATOR:
				return {
					searchbar: true,
					inactiveFilter: true,
				};

			case ItemType.GROUP_TYPE:
				return {
					searchbar: true,
				};
			case ItemType.STATE:
				return {
					searchbar: true,
				};
			default:
				break;
		}
	};

	return (
		<Box
			sx={{
				'& .is_active--false': {
					bgcolor: '#fff2f6',
				},
			}}
		>
			<StyledDataGrid
				autoHeight
				paginationMode="server"
				pageSize={PAGE_SIZE}
				rowCount={rowCount}
				rows={rows}
				rowsPerPageOptions={[PAGE_SIZE]}
				disableColumnMenu={true}
				columns={columns}
				loading={loading}
				onPageChange={onPageChange}
				components={{ Toolbar: MenuTable }}
				componentsProps={{
					toolbar: { applyFilters, handleClickAdd, ...getProps(itemType) },
				}}
				getRowClassName={(params) => `is_active--${params.row?.is_active}`}
			/>
		</Box>
	);
}

interface TableProps extends DataGridProps {
	applyFilters: Function;
	handleClickAdd: Function;
	itemType: ItemType;
}
