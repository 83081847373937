export enum ContactMethod {
	FACEBOOK = 'Facebook',
	INSTAGRAM = 'Instagram',
	EMAIL = 'Email',
	TELEFONO = 'Telefono',
	AUTO_DERIVACION = 'Auto derivacion',
	OTHER = 'Otro',
}

export const contactMethodArray = Object.values(ContactMethod);
