import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import Table from '../../common/components/Table';
import { PAGE_SIZE } from '../../common/constants/page-size';
import { Member } from '../../common/entities/Member';
import { BaseResponse } from '../../common/interfaces/base-response';
import MemberFormModal from '../../common/components/FormModals/MemberFormModal';
import axios from 'axios';
import RowOptionsButton from '../../common/components/RowOptionsButton';
import { ItemType } from '../../common/enums/item-type';
import { useParams } from 'react-router';
import { dateFormater, dateTimeFormater } from '../../common/utils';
import CustomSeparator from '../../common/components/BreadCrumbs';
import { Group } from '../../common/entities/Group';
import { useUser } from '../../common/contexts/userContext';
import { UserRole } from '../../common/enums/user-role.enum';
import Pill from '../../common/components/Pill';
import MamamHeader from '../../common/components/MamamH3';

export default function GroupMembers() {
	const [page, setPage] = useState(0);
	const [searchOptions, setSearchOptions] = useState('');
	const [sendReq, setSendReq] = useState(false);
	const [open, setOpen] = useState(false);
	const [members, setMembers] = useState<BaseResponse<Member>>();
	const [group, setGroup] = useState<Group>();
	const user = useUser();
	const [loading, setLoading] = useState(false);

	const { id } = useParams();
	useEffect(() => {
		setLoading(true);
		axios
			.request({
				method: 'GET',
				url: `/clients/group/${id}?page=${page}&limit=${PAGE_SIZE}${searchOptions}`,
			})
			.then((response) => {
				setMembers(response.data);
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));

		axios
			.request({
				method: 'GET',
				url: `/groups/${id}`,
			})
			.then((response) => {
				setGroup(response.data);
			})
			.catch((error) => console.error(error));
	}, [page, searchOptions, sendReq, id]);

	const applyFilters = (filters: string) => {
		setSearchOptions(filters);
		setSendReq(!sendReq);
	};

	const handlePageChange = (pageNumber: number) => {
		setPage(pageNumber);
		setSendReq(!sendReq);
	};

	const handleClickAdd = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		setSendReq(!sendReq);
	};

	const rows: GridRowsProp =
		members?.data?.map((row) => ({ ...row, options: row.id })) || [];

	const columns: GridColDef[] = [
		{
			field: 'options',
			headerName: `${members?.count || 0}`,
			width: 100,
			cellClassName: 'table-options',
			headerClassName: 'table-options-header',
			renderCell(params) {
				return (
					<RowOptionsButton
						itemId={params.value}
						handleSave={handleSave}
						tabIndex={params.tabIndex}
						itemType={ItemType.GROUP_CLIENT}
						is_active={params.row.is_active}
					/>
				);
			},
		},
		{
			field: 'first_contact',
			headerName: 'Primer contacto',
			width: 130,
			valueFormatter(params) {
				return dateFormater(params.value);
			},
		},
		{
			field: 'state',
			headerName: 'Estado',
			width: 150,
			renderCell: (params) => {
				return (
					params.value &&
					params.value?.name && (
						<Pill
							color={params.value?.label_color}
							text={params.value?.name}
							tabIndex={params.tabIndex}
						/>
					)
				);
			},
		},

		{ field: 'name', headerName: 'Nombre', width: 200 },
		{ field: 'instagram', headerName: 'Instagram', width: 200 },
		{ field: 'phone', headerName: 'Teléfono', width: 200 },
		{ field: 'email', headerName: 'Email', width: 200 },
		{ field: 'contact_method', headerName: 'Metodo de contacto', width: 150 },
		{ field: 'prefered_zone', headerName: 'Zona de interes', width: 180 },
		{
			field: 'modified_at',
			headerName: 'Ultima modificación',
			width: 180,
			valueFormatter(params) {
				return dateTimeFormater(params.value);
			},
		},
	];

	const breadcrumbs = [{ name: group?.name || '', path: '' }];

	user?.role === UserRole.ADMIN &&
		breadcrumbs.unshift({ name: 'Grupos', path: '/grupos' });
	return (
		<div>
			{open && (
				<MemberFormModal
					open={open}
					handleClose={handleClose}
					handleSaveParent={handleSave}
					title="Inscripción miembro"
					parentType={ItemType.GROUP_CLIENT}
					parentId={id}
				/>
			)}
			<CustomSeparator crumbs={breadcrumbs} />
			<MamamHeader text={`Grupo ${group?.name || ''}`} marginBlock="1.5rem" />
			<Table
				rows={rows}
				columns={columns}
				loading={loading}
				onPageChange={handlePageChange}
				rowCount={members?.count}
				applyFilters={applyFilters}
				handleClickAdd={handleClickAdd}
				itemType={ItemType.GROUP_CLIENT}
			></Table>
		</div>
	);
}
