import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ReactNode } from 'react';

export interface MenuSelectInput {
	label: string;
	name: string;
	onChange: Function;
	children: ReactNode;
}

export default function MenuSelect({
	label,
	name,
	onChange,
	children,
}: MenuSelectInput) {
	return (
		<FormControl sx={{ m: 1, minWidth: 150 }} size="small">
			<InputLabel id={`${name}-label`}>{label}</InputLabel>
			<Select
				labelId={`${name}-label`}
				id={`${name}-select`}
				name={name}
				label={label}
				onChange={(e) => onChange(e)}
				defaultValue={''}
			>
				<MenuItem value="">
					<em>----</em>
				</MenuItem>
				{children}
			</Select>
		</FormControl>
	);
}
