interface MamamHeaderProps {
	text: string;
	marginBlock?: string;
	fontSize?: string;
}
export default function MamamHeader({
	text,
	marginBlock,
	fontSize,
}: MamamHeaderProps) {
	return (
		<h3 style={{ marginBlock, fontSize }}>
			<span style={{ color: 'rgb(47,46,45)' }}>{text.toUpperCase()}</span>
			<span style={{ color: '#da8054' }}>.</span>
		</h3>
	);
}
