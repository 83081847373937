import { Box } from '@mui/material';
import NavBar from '../../common/components/NavBar';
import SideBar from '../../common/components/SideBar';
import { Outlet } from 'react-router';
import { useState } from 'react';

const DRAWER_WIDTH = 240;

export default function Layout() {
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	return (
		<Box sx={{ display: 'flex' }}>
			<NavBar
				handleDrawerToggle={handleDrawerToggle}
				drawerWidth={DRAWER_WIDTH}
			/>
			<SideBar
				handleDrawerToggle={handleDrawerToggle}
				mobileOpen={mobileOpen}
				width={DRAWER_WIDTH}
			/>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 3,
					paddingTop: 10,
					width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
				}}
			>
				<Outlet />
			</Box>
		</Box>
	);
}
