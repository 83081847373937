import { ReactNode, useEffect, useRef, useState } from 'react';
import ModalForm, { ModalFormProps } from '../ModalForm';
import { Box, TextField } from '@mui/material';
import { GroupLabels } from '../../../entities/Group';
import { ItemType } from '../../../enums/item-type';
import axios from 'axios';
import { Coordinator } from '../../../entities/Coordinator';
import SearchableSelect from '../../SearchableSelect';
import { useCreateCoordinator } from '../../../requests/create-coordinator';
import { useUpdateCoordinator } from '../../../requests/update-coordinator';
import { getErrorMessage } from '../../../utils';
import { BaseError } from '../../../interfaces/base-error';
import { FormType } from '../../../enums/form-type.enum';

interface CoordinatorFormModalProps
	extends Omit<ModalFormProps, 'children' | 'formType'> {
	children?: ReactNode;
	parentType: ItemType;
	isUpdate?: boolean;
	coordinatorId?: number;
	handleSaveParent: Function;
}
export default function CoordinatorFormModal({
	open,
	handleClose,
	title,
	handleSaveParent,
	parentType,
	coordinatorId,
	isUpdate = false,
}: CoordinatorFormModalProps) {
	const [form, setForm] = useState<Partial<Coordinator>>({});
	const formRef = useRef(null);
	const [groups, setGroups] = useState<GroupLabels[]>([]);
	const createCoordinator = useCreateCoordinator();
	const updateCoordinator = useUpdateCoordinator();
	const [errors, setErrors] = useState({
		email: '',
		name: '',
		serverError: '',
	});

	useEffect(() => {
		if (isUpdate) {
			axios
				.request({ method: 'GET', url: `/coordinators/${coordinatorId}` })
				.then((response) => {
					setForm(response.data);
				})
				.catch((error) => console.error(error));
		}
		axios
			.request({ method: 'GET', url: `/groups/labels` })
			.then((response) => {
				setGroups(response.data.data);
			})
			.catch((error) => console.error(error));

		return setForm({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (e: any): void => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSave = async () => {
		try {
			if (isUpdate && coordinatorId) {
				await updateCoordinator(coordinatorId, form);
			} else {
				await createCoordinator(form);
			}

			handleSaveParent();
			handleClose();
		} catch (error: any) {
			if (error.response && error.response.data && error.response.data.error) {
				const { field, message }: BaseError = error.response.data.error;
				setErrors({ ...errors, [field]: getErrorMessage(message) });
			}
			if (error.code === 'ERR_NETWORK')
				setErrors({ ...errors, serverError: 'true' });
		}
	};

	//modal form ref

	return (
		<ModalForm
			open={open}
			handleClose={handleClose}
			title={title}
			formRef={formRef}
			formType={isUpdate ? FormType.EDIT : FormType.ADD}
			hasServerError={!!errors?.serverError}
		>
			{(!isUpdate || Object.keys(form).length !== 0) && (
				<Box
					component="form"
					sx={{
						'& .MuiTextField-root, .MuiFormControl-root': {
							m: 1,
							marginInline: 3,
							width: '25ch',
						},
					}}
					autoComplete="off"
					ref={formRef}
					onSubmit={(e) => {
						e.preventDefault();
						handleSave();
					}}
				>
					<TextField
						id="name"
						name="name"
						required
						label="Nombre y Apellido"
						placeholder="ej.: Luciana Perez"
						variant="standard"
						value={form?.name}
						onChange={handleChange}
						error={!!errors?.name}
						helperText={errors?.name}
					/>
					<TextField
						id="email"
						name="email"
						required
						label="Email"
						placeholder="ej.: ejemplo@mail.com"
						variant="standard"
						value={form?.email}
						onChange={handleChange}
						error={!!errors?.email}
						helperText={errors?.email}
					/>
					<TextField
						id="tax_number"
						name="tax_number"
						label="Matrícula"
						placeholder="ej.: 30987654"
						variant="standard"
						value={form?.tax_number}
						onChange={handleChange}
					/>

					<TextField
						id="phone"
						name="phone"
						label="Teléfono"
						placeholder="ej.: 12364567890"
						variant="standard"
						value={form?.phone}
						onChange={handleChange}
					/>
					<TextField
						id="instagram"
						name="instagram"
						label="Instagram"
						placeholder="ej.: Juli_prz"
						variant="standard"
						value={form?.instagram}
						onChange={handleChange}
					/>
					<TextField
						id="facebook"
						name="facebook"
						label="Facebook"
						placeholder="ej.: Juliana Perez"
						variant="standard"
						value={form?.facebook}
						onChange={handleChange}
					/>

					<SearchableSelect
						label="Grupo/s"
						items={groups}
						defaultValue={
							form?.groupIds?.map((g) => Number(g)) ||
							form?.groups?.map((g) => g.id)
						}
						valueProperty="name"
						idProperty="id"
						value={
							form?.groupIds?.map((g) => Number(g)) ||
							form?.groups?.map((g) => g.id)
						}
						onChange={handleChange}
						name="groupIds"
						width="56ch !important"
						multiple
					/>
				</Box>
			)}
		</ModalForm>
	);
}
