import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import Table from '../../common/components/Table';
import { PAGE_SIZE } from '../../common/constants/page-size';
import { Member } from '../../common/entities/Member';
import { BaseResponse } from '../../common/interfaces/base-response';

import MemberFormModal from '../../common/components/FormModals/MemberFormModal';
import axios from 'axios';
import RowOptionsButton from '../../common/components/RowOptionsButton';
import { ItemType } from '../../common/enums/item-type';
import Pill from '../../common/components/Pill';
import { Link } from 'react-router-dom';
import { Color } from '../../common/enums/pill-colors';
import CustomSeparator from '../../common/components/BreadCrumbs';
import MamamHeader from '../../common/components/MamamH3';

export default function Members() {
	const [page, setPage] = useState(0);
	const [searchOptions, setSearchOptions] = useState('');
	const [sendReq, setSendReq] = useState(false);
	const [open, setOpen] = useState(false);
	const [clients, setClients] = useState<BaseResponse<Member>>();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		axios
			.request({
				method: 'GET',
				url: `/clients?page=${page}&limit=${PAGE_SIZE}${searchOptions}`,
			})
			.then((response) => {
				setClients(response.data);
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	}, [page, searchOptions, sendReq]);

	const applyFilters = (filters: string) => {
		setSearchOptions(filters);
		setSendReq(!sendReq);
	};

	const handlePageChange = (pageNumber: number) => {
		setPage(pageNumber);
		setSendReq(!sendReq);
	};

	const handleClickAdd = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		setSendReq(!sendReq);
	};

	const rows: GridRowsProp =
		clients?.data?.map((row) => ({ ...row, options: row.id })) || [];

	const columns: GridColDef[] = [
		{
			field: 'options',
			headerName: `${clients?.count || 0}`,
			width: 100,
			cellClassName: 'table-options',
			headerClassName: 'table-options-header',
			renderCell(params) {
				return (
					<RowOptionsButton
						itemId={params.value}
						handleSave={handleSave}
						tabIndex={params.tabIndex}
						itemType={ItemType.MEMBER}
						is_active={params.row.is_active}
					/>
				);
			},
		},
		{ field: 'name', headerName: 'Nombre', width: 200 },
		{ field: 'prefered_zone', headerName: 'Zona de interes', width: 180 },
		{ field: 'instagram', headerName: 'Instagram', width: 200 },
		{ field: 'email', headerName: 'Email', width: 200 },
		{
			field: 'state',
			headerName: 'Estado',
			width: 150,
			renderCell: (params) => {
				return (
					params.value &&
					params.value?.name && (
						<Pill
							color={params.value?.label_color}
							text={params.value?.name}
							tabIndex={params.tabIndex}
						/>
					)
				);
			},
		},
		{
			field: 'group',
			headerName: 'Grupo',
			width: 300,
			renderCell: (params) => {
				return (
					<Link to={`/grupos/${params.value.id}`}>
						<Pill text={params.value.name} color={Color.Blue} />
					</Link>
				);
			},
		},

		{ field: 'contact_method', headerName: 'Metodo de contacto', width: 200 },
	];

	return (
		<div>
			{open && (
				<MemberFormModal
					open={open}
					handleClose={handleClose}
					handleSaveParent={handleSave}
					title="Inscripción miembro"
					parentType={ItemType.MEMBER}
				/>
			)}
			<CustomSeparator crumbs={[{ name: 'Miembros', path: '/miembros' }]} />
			<MamamHeader text="Planilla miembros" marginBlock="1.5rem" />
			<Table
				rows={rows}
				columns={columns}
				loading={loading}
				onPageChange={handlePageChange}
				rowCount={clients?.count}
				applyFilters={applyFilters}
				handleClickAdd={handleClickAdd}
				itemType={ItemType.MEMBER}
			></Table>
		</div>
	);
}
