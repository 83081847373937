import axios, { AxiosRequestConfig } from 'axios';
import { useToken } from '../contexts/tokenContext';
import { Coordinator } from '../entities/Coordinator';

export const useUpdateCoordinator = () => {
	const token = useToken();
	return async (
		coordinatorId: number,
		form: any
	): Promise<Coordinator | null> => {
		try {
			if (!token?.accessToken) {
				return null;
			}

			form.groupIds = form?.groupIds?.map((g: string) => Number(g)) || null;
			delete form.groups;
			delete form.id;
			delete form.user;
			delete form.is_active;

			const params: AxiosRequestConfig = {
				method: 'PATCH',
				url: `/coordinators/${coordinatorId}`,
				data: form,
				withCredentials: true,
			};
			const result = await axios.request(params);
			return result.data;
		} catch (error) {
			throw error;
		}
	};
};
