import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '../../common/components/Table';
import { PAGE_SIZE } from '../../common/constants/page-size';
import { BaseResponse } from '../../common/interfaces/base-response';
import { ItemType } from '../../common/enums/item-type';
import { Group } from '../../common/entities/Group';
import CustomSeparator from '../../common/components/BreadCrumbs';
import { Box } from '@mui/material';
import MamamHeader from '../../common/components/MamamH3';

export default function GroupsList() {
	const [page, setPage] = useState(0);
	const [searchOptions, setSearchOptions] = useState('');
	const [sendReq, setSendReq] = useState(false);
	const [groups, setGroups] = useState<BaseResponse<Group>>();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		axios
			.request({
				method: 'GET',
				url: `/groups/lists?page=${page}&limit=${PAGE_SIZE}${searchOptions}`,
			})
			.then((response) => {
				setGroups(response.data);
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	}, [page, searchOptions, sendReq]);

	const applyFilters = (filters: string) => {
		setSearchOptions(filters);
		setSendReq(!sendReq);
	};

	const handlePageChange = (pageNumber: number) => {
		setPage(pageNumber);
		setSendReq(!sendReq);
	};

	const handleClickAdd = () => {};

	const rows: GridRowsProp =
		groups?.data?.map((row) => ({ ...row, options: row.id })) || [];

	const columns: GridColDef[] = [
		{
			field: 'group_type',
			headerName: 'Tipo de grupo',
			width: 220,
			renderCell: (params) => {
				return params.value && params.value?.name && params.value.name;
			},
		},
		{ field: 'zone', headerName: 'Localidad', width: 220 },
		{ field: 'address', headerName: 'Dirección', width: 220 },
		{ field: 'schedule', headerName: 'Horario', width: 220 },
		{
			field: 'coordinators',
			headerName: 'Coordinadora/s',
			width: 420,
			renderCell(params) {
				const coords = params?.value?.map((coord: any, i: number) => {
					return (
						<p style={{ margin: 0 }} key={i}>
							{coord.name}
						</p>
					);
				});
				return (
					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							flexDirection: 'column',
							gap: 1,
							marginBlock: 1,
						}}
					>
						{coords}
					</Box>
				);
			},
		},
	];

	return (
		<div>
			<CustomSeparator
				crumbs={[{ name: 'Listado Grupos', path: '/listado-grupos' }]}
			/>
			<MamamHeader text="Listado de grupos" marginBlock="1.5rem" />
			<Table
				rows={rows}
				columns={columns}
				loading={loading || !groups?.data}
				onPageChange={handlePageChange}
				rowCount={groups?.count}
				applyFilters={applyFilters}
				handleClickAdd={handleClickAdd}
				itemType={ItemType.GROUP_LIST}
			></Table>
		</div>
	);
}
