import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
	ExpandLess,
	ExpandMore,
	Home,
	SegmentOutlined,
} from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';

import LogoEntero from '../../assets/logo-entero.png';

import { Collapse } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import { useUser } from '../../contexts/userContext';
import { UserRole } from '../../enums/user-role.enum';
import styled from '@emotion/styled';
import { useCallback, useMemo, useState } from 'react';
import ListItemLink from '../ListItemLink';

const ListIconStyled = styled(ListItemIcon)(() => ({
	color: '#EA7953',
}));

const NestedList = styled(ListItem)(() => ({
	padding: '0 !important',
}));

interface Props {
	window?: () => Window;
	handleDrawerToggle: () => void;
	mobileOpen: boolean;
	width: number;
}

const tabs = [
	{ to: '/interesadas', primary: 'Interesadas' },
	{ to: '/miembros', primary: 'Miembros' },

	{ to: '/grupos', primary: 'Grupos' },
	{ to: '/coordinadoras', primary: 'Coordinadoras' },
];

const tabsBackoffice = [
	{ to: '/tipos-grupo', primary: 'Tipos de grupo' },
	{ to: '/estados', primary: 'Estados' },
];

export default function SideBar({
	window,
	mobileOpen,
	handleDrawerToggle,
	width,
}: Props) {
	const [open, setOpen] = useState({
		planillas: true,
		backoffice: false,
	});
	const [selectedIndex, setSelectedIndex] = useState(-1);

	const user = useUser();
	const isAdmin = useMemo(() => user?.role === UserRole.ADMIN, [user?.role]);

	const handleClick = useCallback(
		(index: number) => {
			setSelectedIndex(index);
			if (index === 1)
				setOpen((prevOpen) => ({ ...prevOpen, planillas: !open.planillas }));
			if (index === 3)
				setOpen((prevOpen) => ({ ...prevOpen, backoffice: !open.backoffice }));
		},
		[open]
	);

	const drawer = (
		<div>
			<img src={LogoEntero} alt="logo" style={{ width: '240px' }} />
			<List>
				{/* Home */}
				<ListItemLink
					to="/"
					primary="Home"
					icon={<Home />}
					onClick={() => handleClick(0)}
					selected={selectedIndex === 0}
				/>
				{/* Planillas */}
				<li>
					<ListItemButton
						onClick={() => handleClick(1)}
						selected={
							(selectedIndex === 1 && open.planillas) ||
							(selectedIndex >= 100 && selectedIndex < 300)
						}
					>
						<ListIconStyled>
							<SegmentOutlined />
						</ListIconStyled>
						<ListItemText primary="Planillas" />
						{open.planillas ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
				</li>
				<NestedList>
					<Collapse in={open.planillas} timeout="auto" unmountOnExit>
						<List>
							{isAdmin &&
								tabs.map((tab, index) => {
									const itemIndex = index + 100;
									return (
										<ListItemLink
											to={tab.to}
											primary={tab.primary}
											key={index}
											nested
											onClick={() => handleClick(itemIndex)}
											selected={selectedIndex === itemIndex}
										/>
									);
								})}
							{user?.groups?.map((group, index) => {
								const itemIndex = index + 200;
								return (
									<ListItemLink
										to={`/grupos/${group.id}`}
										primary={group.name}
										key={index}
										nested
										onClick={() => handleClick(itemIndex)}
										selected={selectedIndex === itemIndex}
									/>
								);
							})}
						</List>
					</Collapse>
				</NestedList>

				{/* Listado grupos */}
				<ListItemLink
					to="/listado-grupos"
					primary="Listado Grupos"
					icon={<ListIcon />}
					onClick={() => handleClick(2)}
					selected={selectedIndex === 2}
				/>
			</List>
			<Divider />
			{isAdmin && (
				<List>
					{/* Backoffice */}
					<li>
						<ListItemButton
							onClick={() => handleClick(3)}
							selected={
								(selectedIndex === 3 && open.backoffice) ||
								(selectedIndex >= 300 && selectedIndex < 400)
							}
						>
							<ListIconStyled>
								<StorageIcon />
							</ListIconStyled>
							<ListItemText primary="Backoffice" />
							{open.backoffice ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
					</li>
					<NestedList>
						<Collapse
							in={open.backoffice}
							timeout="auto"
							unmountOnExit
							sx={{ width: '100%' }}
						>
							<List>
								{tabsBackoffice.map((tab, index) => {
									const itemIndex = index + 300;
									return (
										<ListItemLink
											to={tab.to}
											primary={tab.primary}
											key={index}
											nested
											onClick={() => handleClick(itemIndex)}
											selected={selectedIndex === itemIndex}
										/>
									);
								})}
							</List>
						</Collapse>
					</NestedList>
				</List>
			)}
		</div>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<Box
			component="nav"
			sx={{ width: { sm: width }, flexShrink: { sm: 0 } }}
			aria-label="mailbox folders"
		>
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Drawer
				container={container}
				variant="temporary"
				open={mobileOpen}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: width,
						backgroundColor: '#ECE8E2 !important',
					},
				}}
			>
				{drawer}
			</Drawer>
			<Drawer
				variant="permanent"
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: width,
						backgroundColor: '#ECE8E2',
						border: 'none',
					},
				}}
				open
			>
				{drawer}
			</Drawer>
		</Box>
	);
}
