import { createContext, ReactNode, useContext, useState } from 'react';
import { AuthToken } from '../../interfaces/auth-token.interface';

export const TokenContext = createContext<AuthToken | null>(null);
const TokenUpdateContext = createContext<Function>(() => {});

export const useToken = () => {
	return useContext(TokenContext);
};

export const useTokenUpdate = () => {
	return useContext(TokenUpdateContext);
};

export default function AuthTokenContext({
	children,
}: {
	children: ReactNode;
}) {
	const [token, setToken] = useState<AuthToken | null>(null);

	function saveToken(u: AuthToken) {
		setToken(u);
	}

	return (
		<TokenContext.Provider value={token}>
			<TokenUpdateContext.Provider value={saveToken}>
				{children}
			</TokenUpdateContext.Provider>
		</TokenContext.Provider>
	);
}
