import axios from 'axios';
import { AuthToken } from '../interfaces/auth-token.interface';

export const refreshToken = async (): Promise<AuthToken> => {
	try {
		const response = await axios.request<AuthToken>({
			method: 'POST',
			url: '/auth/refresh_token',
			withCredentials: true,
		});

		axios.defaults.headers.common[
			'Authorization'
		] = `Bearer ${response.data.accessToken}`;

		return response.data;
	} catch (error) {
		console.log(error);
		return { accessToken: '' };
	}
};
