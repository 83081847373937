import styled from '@emotion/styled';
import { ReactNode } from 'react';

interface BoxContainerProp {
	children: ReactNode;
	width: string;
	little?: boolean;
}

const Card = styled('div')(
	({ width, little }: { width: string; little: boolean }) => ({
		border: '2px solid rgb(211, 218, 230)',
		padding: '0.75rem',
		borderRadius: '0.375rem',
		width: width || '40vw',
		margin: '0.5rem',
		'@media(max-width:700px)': {
			marginInline: 'auto',
			width: little ? '35vw' : '80vw',
			marginBlock: '0.5rem',
		},
	})
);
export default function BoxContainer({
	children,
	width,
	little = false,
}: BoxContainerProp) {
	return (
		<Card width={width} little={little}>
			{children}
		</Card>
	);
}
