import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router';
import axios from 'axios';
import { Groups2Outlined } from '@mui/icons-material';
import MamamHeader from '../../common/components/MamamH3';
import ContactContainer from '../../common/components/ContactContainer';
import Pill from '../../common/components/Pill';
import { Color } from '../../common/enums/pill-colors';
import InfoContainer from '../../common/components/InfoContainer';
import { Coordinator } from '../../common/entities/Coordinator';
import { Link } from 'react-router-dom';

const Cards = styled('div')(() => ({
	display: 'flex',
	'@media(max-width:700px)': {
		flexDirection: 'column',
	},
}));

const Container = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',

	width: '100%',
}));
export default function CoordinatorDetails() {
	const [coordinator, setCoordinator] = useState<Partial<Coordinator>>();

	const { id } = useParams();

	useEffect(() => {
		axios
			.request({ method: 'GET', url: `/coordinators/${id}/details` })
			.then((response) => {
				setCoordinator(response.data);
			})
			.catch((error) => console.error(error));

		return setCoordinator({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<MamamHeader text={`Detalle de ${coordinator?.name || 'coordinadora'}`} />
			<hr
				style={{ backgroundColor: 'rgb(211, 218, 230)', marginBlock: '16px' }}
			></hr>
			<Container>
				<Cards>
					<InfoContainer
						width="25rem"
						title={
							coordinator?.groups && coordinator?.groups?.length > 1
								? 'Grupos'
								: 'Grupo'
						}
						flexDirection="column"
						icon={<Groups2Outlined />}
					>
						{coordinator?.groups?.map((group, i) => {
							return (
								<Link
									to={`/grupos/${group.id}`}
									key={i}
									style={{ marginBlock: '0.35rem' }}
								>
									<Pill text={group.name} color={Color.Blue} />
								</Link>
							);
						})}
					</InfoContainer>

					<div>
						<ContactContainer
							width="25rem"
							facebook={coordinator?.facebook}
							instagram={coordinator?.instagram}
							email={coordinator?.email}
							phone={coordinator?.phone}
						/>
					</div>
				</Cards>
			</Container>
		</div>
	);
}
