import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '../../common/components/Table';
import { PAGE_SIZE } from '../../common/constants/page-size';
import { BaseResponse } from '../../common/interfaces/base-response';
import RowOptionsButton from '../../common/components/RowOptionsButton';
import { ItemType } from '../../common/enums/item-type';
import { State } from '../../common/entities/State';
import StateFormModal from '../../common/components/FormModals/StateFormModal';
import Pill from '../../common/components/Pill';
import CustomSeparator from '../../common/components/BreadCrumbs';
import MamamHeader from '../../common/components/MamamH3';

export default function States() {
	const [page, setPage] = useState(0);
	const [searchOptions, setSearchOptions] = useState('');
	const [sendReq, setSendReq] = useState(false);
	const [open, setOpen] = useState(false);
	const [states, setStates] = useState<BaseResponse<State>>();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		axios
			.request({
				method: 'GET',
				url: `/states?page=${page}&limit=${PAGE_SIZE}${searchOptions}`,
			})
			.then((response) => {
				setStates(response.data);
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	}, [page, searchOptions, sendReq]);

	const applyFilters = (filters: string) => {
		setSearchOptions(filters);
		setSendReq(!sendReq);
	};

	const handlePageChange = (pageNumber: number) => {
		setPage(pageNumber);
		setSendReq(!sendReq);
	};

	const handleClickAdd = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		setSendReq(!sendReq);
	};

	const rows: GridRowsProp =
		states?.data?.map((row) => ({ ...row, options: row.id })) || [];

	const columns: GridColDef[] = [
		{
			field: 'options',
			headerName: '',
			width: 100,
			cellClassName: 'table-options',
			headerClassName: 'table-options-header',
			renderCell(params) {
				return (
					<RowOptionsButton
						itemId={params.value}
						handleSave={handleSave}
						tabIndex={params.tabIndex}
						itemType={ItemType.STATE}
					/>
				);
			},
		},
		{
			field: 'name',
			headerName: 'Nombre',
			width: 270,
			renderCell(params) {
				const row = params.row;
				return <Pill text={row.name} color={row.label_color} />;
			},
		},
		{
			field: 'description',
			headerName: 'Descripción',
			width: 620,
			flex: 1,
			renderCell(params) {
				const row = params.row;
				return <p>{row.description}</p>;
			},
		},
	];

	return (
		<div>
			{open && (
				<StateFormModal
					open={open}
					handleClose={handleClose}
					handleSaveParent={handleSave}
					title="Agregar estado"
					parentType={ItemType.STATE}
				/>
			)}
			<CustomSeparator crumbs={[{ name: 'Estados', path: '/estados' }]} />
			<MamamHeader text="Estados" marginBlock="1.5rem" />
			<Table
				rows={rows}
				columns={columns}
				loading={loading || !states?.data}
				onPageChange={handlePageChange}
				rowCount={states?.count}
				applyFilters={applyFilters}
				handleClickAdd={handleClickAdd}
				itemType={ItemType.STATE}
			></Table>
		</div>
	);
}
