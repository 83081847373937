import { ReactNode, useEffect, useRef, useState } from 'react';
import { Box, MenuItem, TextField } from '@mui/material';
import axios from 'axios';
import { ItemType } from '../../../enums/item-type';
import ModalForm, { ModalFormProps } from '../ModalForm';
import { State } from '../../../entities/State';
import { useUpdateState } from '../../../requests/update-states';
import { useCreateState } from '../../../requests/create-state';
import { colorsArray } from '../../../enums/pill-colors';
import Pill from '../../Pill';
import { colorNameSpanish } from '../../../utils';
import { FormType } from '../../../enums/form-type.enum';

interface StateFormModalProps
	extends Omit<ModalFormProps, 'children' | 'formType'> {
	children?: ReactNode;
	parentType: ItemType;
	isUpdate?: boolean;
	groupTypeId?: number;
	handleSaveParent: Function;
}
export default function StateFormModal({
	open,
	handleClose,
	title,
	handleSaveParent,
	parentType,
	groupTypeId: stateId,
	isUpdate = false,
}: StateFormModalProps) {
	const [form, setForm] = useState<Partial<State>>({});
	const [errors, setErrors] = useState({ serverError: '' });
	const formRef = useRef(null);
	const createState = useCreateState();
	const updateState = useUpdateState();

	useEffect(() => {
		if (isUpdate) {
			axios
				.request({ method: 'GET', url: `/states/${stateId}` })
				.then((response) => {
					setForm(response.data);
				})
				.catch((error) => console.error(error));
		}
		return setForm({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (e: any): void => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSave = async () => {
		try {
			if (isUpdate && stateId) {
				delete form.order;
				await updateState(stateId, form);
			} else {
				await createState(form);
			}

			handleSaveParent();
			handleClose();
		} catch (error: any) {
			if (error.code === 'ERR_NETWORK')
				setErrors({ ...errors, serverError: 'true' });
		}
	};

	//modal form ref

	return (
		<ModalForm
			open={open}
			handleClose={handleClose}
			title={title}
			formRef={formRef}
			formType={isUpdate ? FormType.EDIT : FormType.ADD}
			hasServerError={!!errors?.serverError}
		>
			{(!isUpdate || Object.keys(form).length !== 0) && (
				<Box
					component="form"
					sx={{
						'& .MuiTextField-root, .MuiFormControl-root': {
							m: 1,
							marginInline: 3,
							width: '25ch',
						},
					}}
					autoComplete="off"
					ref={formRef}
					onSubmit={(e) => {
						e.preventDefault();
						handleSave();
					}}
				>
					<TextField
						// required
						id="name"
						name="name"
						label="Nombre"
						placeholder="ej.: Derivada"
						variant="standard"
						value={form.name}
						onChange={handleChange}
					/>

					<TextField
						// required
						id="label_color"
						name="label_color"
						label="Color"
						select
						variant="standard"
						value={form.label_color}
						onChange={handleChange}
					>
						{colorsArray?.map((color, index) => (
							<MenuItem value={color} key={index}>
								<Pill text={colorNameSpanish(color)} color={color} />
							</MenuItem>
						))}
					</TextField>
					<TextField
						// required
						sx={{
							width: '55ch !important',
						}}
						id="description"
						name="description"
						label="Descripción"
						placeholder="ej.: Usado cuando se deriva a alguien."
						variant="standard"
						value={form.description}
						onChange={handleChange}
					/>
				</Box>
			)}
		</ModalForm>
	);
}
