import {
	FacebookOutlined,
	EmailOutlined,
	LocalPhoneOutlined,
	Instagram,
	ContactPageOutlined,
	ConnectWithoutContactOutlined,
} from '@mui/icons-material/';
import ContainerHeader from '../ContainerHeader';
import TableIconItem from '../TableIconItem';
import styled from '@emotion/styled';
import BoxContainer from '../BoxContainer';

const TableItem = styled('p')(() => ({
	margin: '0.75rem 0 0 0 ',
	height: '24px',
}));
interface ContactContainerProp {
	facebook?: string;
	instagram?: string;
	phone?: string;
	email?: string;
	width: string;
	contactMethod?: string;
}
export default function ContactContainer({
	facebook,
	instagram,
	phone,
	email,
	width,
	contactMethod,
}: ContactContainerProp) {
	return (
		<BoxContainer width={width}>
			<ContainerHeader icon={<ContactPageOutlined />}>Contacto</ContainerHeader>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					padding: '0.75rem',
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<TableIconItem icon={<EmailOutlined />}>Email:</TableIconItem>
					<TableIconItem icon={<LocalPhoneOutlined />}>Teléfono:</TableIconItem>
					<TableIconItem icon={<Instagram />}>Instagram:</TableIconItem>
					<TableIconItem icon={<FacebookOutlined />}>Facebook:</TableIconItem>
					{contactMethod && (
						<TableIconItem icon={<ConnectWithoutContactOutlined />}>
							Método de contacto:
						</TableIconItem>
					)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							textAlign: 'right',
						}}
					>
						<TableItem>{email || '---'}</TableItem>
						<TableItem>{phone || '---'}</TableItem>
						<TableItem>{instagram || '---'}</TableItem>
						<TableItem>{facebook || '---'}</TableItem>
						{contactMethod && <TableItem>{contactMethod || '---'}</TableItem>}
					</div>
				</div>
			</div>
		</BoxContainer>
	);
}
