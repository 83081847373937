import { ReactNode } from 'react';

interface ContainerHeaderProp {
	children: ReactNode;
	icon?: ReactNode;
}
export default function ContainerHeader({
	children,
	icon,
}: ContainerHeaderProp) {
	return (
		<div
			style={{
				borderBottom: '1px solid rgb(211, 218, 230)',
				paddingBottom: '0.75rem',
				display: 'flex',
			}}
		>
			{icon}
			<p
				style={{
					textAlign: 'left',
					fontWeight: 'bold',
					margin: '0 0 0 0.5rem',
					alignSelf: 'center',
				}}
			>
				{children}
			</p>
		</div>
	);
}
