import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Context from './common/contexts/userContext';
import AuthTokenContext from './common/contexts/tokenContext';
import { ThemeProvider, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
const theme = createTheme({
	palette: {
		primary: {
			main: '#2B4EBF',
		},
		secondary: {
			light: '#EDB0C0',
			main: '#EA7953',
		},
	},
});

root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<AuthTokenContext>
				<Context>
					<App />
				</Context>
			</AuthTokenContext>
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
