export enum ItemType {
	GROUP = 'groups',
	GROUP_LIST = 'groups_list',

	GROUP_TYPE = 'group_type',
	GROUP_CLIENT = 'groupClient',
	INTERESTED = 'interesteds',
	STATE = 'states',
	MEMBER = 'clients',
	COORDINATOR = 'coordinators',
}
