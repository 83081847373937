export enum ErrorMessage {
	EMAIL_DOESNT_EXIST = 'Email no existente.',
	INVALID_USER = 'Usuario o contraseña incorrecto.',
	SERVER_ERROR = 'Error del servidor, intente denuevo más tarde.',
	EXPIRED_ERROR = 'Se agoto el tiempo para el cambio de contraseña \n. Por favor, vuelva a empezar el procedimiento de recuperación.',
	WRONG_PASSWORD = 'La contraseña actual ingesada es incorrecta.',

	REUTILIZED_HASH_ERROR = 'El código de recuperación ya fue utilizado.',
	EMAIL_EXISTS = 'Email ya existente.',
	PHONE_EXISTS = 'Teléfono ya existente.',
	NAME_EXISTS = 'Nombre ya existente.',
	DEACTIVATED_USER = 'Usuario desactivado',
}
