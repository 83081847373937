import { ReactNode, useEffect, useRef, useState } from 'react';
import ModalForm, { ModalFormProps } from '../ModalForm';
import { Box } from '@mui/material';
import { ItemType } from '../../../enums/item-type';
import { FormType } from '../../../enums/form-type.enum';
import { useRestoreItem } from '../../../requests/restore-item';

interface RestoreModalProps
	extends Omit<ModalFormProps, 'children' | 'formType'> {
	children?: ReactNode;
	itemId: number;
	handleSaveParent: Function;
	parentType: ItemType;
}
export default function RestoreModal({
	open,
	handleClose,
	title,
	itemId,
	handleSaveParent,
	parentType,
}: RestoreModalProps) {
	const restoreItem = useRestoreItem();
	const formRef = useRef(null);
	const [errors, setErrors] = useState({ serverError: '' });

	useEffect(() => {}, []);

	const handleSave = async () => {
		try {
			await restoreItem(+itemId, parentType);
			handleSaveParent();
			handleClose();
		} catch (error: any) {
			if (error.code === 'ERR_NETWORK')
				setErrors({ ...errors, serverError: 'true' });
		}
	};

	//modal form ref

	return (
		<ModalForm
			open={open}
			handleClose={handleClose}
			title={title}
			formRef={formRef}
			formType={FormType.RESTORE}
			hasServerError={!!errors?.serverError}
		>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root, .MuiFormControl-root': {
						m: 1,
						marginInline: 3,
						width: '25ch',
					},
				}}
				autoComplete="off"
				ref={formRef}
				onSubmit={(e) => {
					e.preventDefault();
					handleSave();
				}}
			>
				Esta seguro que quire recuperar este elemento?
			</Box>
		</ModalForm>
	);
}
