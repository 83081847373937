import styled from '@emotion/styled';
import { ReactNode } from 'react';

interface TableIconItemProp {
	icon: ReactNode;
	children: ReactNode;
}

const TableItem = styled('p')(() => ({
	margin: '0 0 0 0.75rem',
	alignSelf: 'center',
}));

export default function TableIconItem({ icon, children }: TableIconItemProp) {
	return (
		<div style={{ display: 'flex', marginTop: '0.75rem' }}>
			{icon}

			<TableItem style={{ fontWeight: 500 }}>{children}</TableItem>
		</div>
	);
}
