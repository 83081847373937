import styled from '@emotion/styled';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { MouseEventHandler, forwardRef } from 'react';
import {
	Link as RouterLink,
	LinkProps as RouterLinkProps,
} from 'react-router-dom';

const ListIconStyled = styled(ListItemIcon)(() => ({
	color: '#EA7953',
}));

const Link = forwardRef<HTMLAnchorElement, RouterLinkProps>(
	function Link(itemProps, ref) {
		return <RouterLink ref={ref} {...itemProps} role={undefined} />;
	}
);

interface ListItemLinkProps {
	icon?: React.ReactElement;
	primary: string;
	to: string;
	nested?: boolean;
	selected?: boolean;
	onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export default function ListItemLink(props: ListItemLinkProps) {
	const { icon, primary, to, nested, selected, onClick } = props;

	return (
		<li>
			<ListItemButton
				component={Link}
				to={to}
				sx={{ pl: nested ? 6 : 2 }}
				selected={selected}
				onClick={onClick}
			>
				{icon ? <ListIconStyled>{icon}</ListIconStyled> : null}
				<ListItemText primary={primary} />
			</ListItemButton>
		</li>
	);
}
