import {
	Divider,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
} from '@mui/material';
import { useState } from 'react';
import {
	Delete,
	Edit,
	MoreVert,
	TrendingFlat,
	ArtTrack,
	Undo,
} from '@mui/icons-material';
import DerivateModal from '../FormModals/DerivateModal';
import MemberFormModal from '../FormModals/MemberFormModal';
import { ItemType } from '../../enums/item-type';
import DeleteModal from '../FormModals/DeleteModal';
import GroupFormModal from '../FormModals/GroupFormModal';
import CoordinatorFormModal from '../FormModals/CoordinatorFormModal';
import GroupTypeFormModal from '../FormModals/GroupTypeFormModal';
import StateFormModal from '../FormModals/StateFormModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
	itemTypeToName,
	itemTypeToRealType,
	itemTypeToUrlName,
} from '../../utils';
import RestoreModal from '../FormModals/RestoreModal';

interface IRowOptionsButton {
	itemId: number;
	handleSave: Function;
	is_active?: boolean;
	itemType: ItemType;
	tabIndex: number;
}
const PaperProps = {
	elevation: 0,
	sx: {
		overflow: 'visible',
		filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
		mt: 1.5,
		'& .MuiAvatar-root': {
			width: 32,
			height: 32,
			ml: -0.5,
			mr: 1,
		},
		'&:before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 0,
			left: 14,
			width: 10,
			height: 10,
			bgcolor: 'background.paper',
			transform: 'translateY(-50%) rotate(45deg)',
			zIndex: 0,
		},
	},
};
export default function RowOptionsButton({
	itemId,
	handleSave,
	tabIndex,
	itemType,
	is_active,
}: IRowOptionsButton) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openModals, setOpenModals] = useState({
		derivate: false,
		edit: false,
		delete: false,
		details: false,
		restore: false,
	});
	const location = useLocation();
	const navigation = useNavigate();
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenDerivate = () => {
		setOpenModals({ ...openModals, derivate: true });
	};
	const handleCloseDerivate = () => {
		setOpenModals({ ...openModals, derivate: false });
	};

	const handleOpenEdit = () => {
		setOpenModals({ ...openModals, edit: true });
	};
	const handleCloseEdit = () => {
		setOpenModals({ ...openModals, edit: false });
	};

	const handleOpenDelete = () => {
		setOpenModals({ ...openModals, delete: true });
	};
	const handleCloseDelete = () => {
		setOpenModals({ ...openModals, delete: false });
	};

	const handleOpenRestore = () => {
		setOpenModals({ ...openModals, restore: true });
	};
	const handleCloseRestore = () => {
		setOpenModals({ ...openModals, restore: false });
	};
	const handleDetails = () => {
		console.log(itemId);
	};

	const openEditModal = () => {
		switch (itemType) {
			case ItemType.GROUP_CLIENT:
			case ItemType.INTERESTED:
			case ItemType.MEMBER:
				return (
					<MemberFormModal
						title="Editar miembro"
						handleSaveParent={handleSave}
						parentType={itemType}
						isUpdate={true}
						open={openModals.edit}
						clientId={itemId}
						handleClose={handleCloseEdit}
					/>
				);
			case ItemType.GROUP:
				return (
					<GroupFormModal
						title="Editar grupo"
						handleSaveParent={handleSave}
						parentType={itemType}
						isUpdate={true}
						open={openModals.edit}
						groupId={itemId}
						handleClose={handleCloseEdit}
					/>
				);
			case ItemType.COORDINATOR:
				return (
					<CoordinatorFormModal
						title="Editar coordinadora"
						handleSaveParent={handleSave}
						parentType={itemType}
						isUpdate={true}
						open={openModals.edit}
						coordinatorId={itemId}
						handleClose={handleCloseEdit}
					/>
				);

			case ItemType.GROUP_TYPE:
				return (
					<GroupTypeFormModal
						title="Editar tipo de grupo"
						handleSaveParent={handleSave}
						parentType={itemType}
						isUpdate={true}
						open={openModals.edit}
						groupTypeId={itemId}
						handleClose={handleCloseEdit}
					/>
				);

			case ItemType.STATE:
				return (
					<StateFormModal
						title="Editar estado"
						handleSaveParent={handleSave}
						parentType={itemType}
						isUpdate={true}
						open={openModals.edit}
						groupTypeId={itemId}
						handleClose={handleCloseEdit}
					/>
				);
		}
	};

	const renderDetailsOption = () => {
		return (
			<MenuItem onClick={handleDetails}>
				<Link
					onClick={(e) => {
						e.preventDefault();
						location.state = { prevPath: location.pathname };
						navigation(`/${itemTypeToUrlName(itemType)}/${itemId}`);
					}}
					to={`/${itemTypeToUrlName(itemType)}/${itemId}`}
					style={{
						textDecoration: 'none',
						color: 'inherit',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<ListItemIcon>
						<ArtTrack fontSize="small" />
					</ListItemIcon>
					Detalles
				</Link>
			</MenuItem>
		);
	};

	const renderDreivateOption = () => {
		return (
			<MenuItem onClick={handleOpenDerivate}>
				<ListItemIcon>
					<TrendingFlat fontSize="small" />
				</ListItemIcon>
				Derivar
			</MenuItem>
		);
	};

	return (
		<>
			{openModals.derivate && (
				<DerivateModal
					title="Derivación"
					open={openModals.derivate}
					handleSaveParent={handleSave}
					clientId={itemId}
					handleClose={handleCloseDerivate}
				/>
			)}
			{openModals.edit && openEditModal()}
			{openModals.restore && (
				<RestoreModal
					title={`Recuperar ${itemTypeToName(itemType)}`}
					handleSaveParent={handleSave}
					open={openModals.restore}
					itemId={itemId}
					handleClose={handleCloseRestore}
					parentType={itemTypeToRealType(itemType)}
				/>
			)}
			{openModals.delete && (
				<DeleteModal
					title={`Eliminar ${itemTypeToName(itemType)}`}
					handleSaveParent={handleSave}
					open={openModals.delete}
					itemId={itemId}
					handleClose={handleCloseDelete}
					parentType={itemTypeToRealType(itemType)}
				/>
			)}
			<IconButton
				color="inherit"
				aria-label="open user menu"
				aria-controls={open ? 'account-menu' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
				aria-expanded={open ? 'true' : undefined}
				tabIndex={tabIndex}
			>
				<MoreVert />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				title="Opciones"
				PaperProps={PaperProps}
				transformOrigin={{ horizontal: 'left', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
			>
				{[
					ItemType.GROUP,
					ItemType.MEMBER,
					ItemType.GROUP_CLIENT,
					ItemType.INTERESTED,
					ItemType.COORDINATOR,
				].includes(itemType) && renderDetailsOption()}
				{[ItemType.MEMBER, ItemType.GROUP_CLIENT, ItemType.INTERESTED].includes(
					itemType
				) && renderDreivateOption()}
				<MenuItem onClick={handleOpenEdit}>
					<ListItemIcon>
						<Edit fontSize="small" />
					</ListItemIcon>
					Editar
				</MenuItem>
				<Divider />
				{![ItemType.STATE, ItemType.GROUP_TYPE].includes(itemType) &&
					!is_active && (
						<MenuItem onClick={handleOpenRestore}>
							<ListItemIcon>
								<Undo fontSize="small" />
							</ListItemIcon>
							Recuperar
						</MenuItem>
					)}
				<MenuItem onClick={handleOpenDelete}>
					<ListItemIcon>
						<Delete fontSize="small" />
					</ListItemIcon>
					{[ItemType.STATE, ItemType.GROUP_TYPE].includes(itemType) ||
					!is_active
						? 'Eliminar'
						: 'Dar de baja'}
				</MenuItem>
			</Menu>
		</>
	);
}
