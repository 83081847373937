import { ReactNode } from 'react';

interface ScrollContainerProp {
	children: ReactNode;
	maxHeight: string;
}
export default function ScrollContainer({
	children,
	maxHeight,
}: ScrollContainerProp) {
	return (
		<div
			style={{
				maxHeight,
				overflow: 'auto',
				paddingTop: '0.75rem',
			}}
		>
			{children}
		</div>
	);
}
