import { ReactNode, useEffect, useRef, useState } from 'react';
import ModalForm, { ModalFormProps } from '../ModalForm';
import { Box, MenuItem, TextField } from '@mui/material';
import { GroupLabels } from '../../../entities/Group';
import { ItemType } from '../../../enums/item-type';
import { contactMethodArray } from '../../../enums/contact-method';
import axios from 'axios';
import { State } from '../../../entities/State';
import { Member } from '../../../entities/Member';
import Pill from '../../Pill';
import { useCreateClient } from '../../../requests/create-client';
import { useUpdateClient } from '../../../requests/update-client';
import { Color } from '../../../enums/pill-colors';
import SearchableSelect from '../../SearchableSelect';
import { FormType } from '../../../enums/form-type.enum';
import { useCreateInterested } from '../../../requests/create-interested';
import { BaseError } from '../../../interfaces/base-error';
import { getErrorMessage } from '../../../utils';

interface MemberFormModalProps
	extends Omit<ModalFormProps, 'children' | 'formType'> {
	children?: ReactNode;
	parentType: ItemType;
	isUpdate?: boolean;
	clientId?: number;
	handleSaveParent: Function;
	parentId?: string;
}
export default function MemberFormModal({
	open,
	handleClose,
	title,
	handleSaveParent,
	parentType,
	clientId,
	isUpdate = false,
	parentId,
}: MemberFormModalProps) {
	const [form, setForm] = useState<Partial<Member>>({});
	const formRef = useRef(null);
	const [states, setStates] = useState<State[]>([]);
	const [groups, setGroups] = useState<GroupLabels[]>([]);
	const [errors, setErrors] = useState({
		serverError: '',
		email: '',
		phone: '',
	});
	const createClient = useCreateClient();
	const createInterested = useCreateInterested();
	const updateClient = useUpdateClient();

	const isInterested = parentType === ItemType.INTERESTED;
	const isGroup = parentType === ItemType.GROUP_CLIENT;

	useEffect(() => {
		if (isUpdate) {
			axios
				.request({ method: 'GET', url: `/clients/${clientId}` })
				.then((response) => {
					setForm(response.data);
				})
				.catch((error) => console.error(error));

			axios
				.request({ method: 'GET', url: `/states` })
				.then((response) => {
					setStates(response.data.data);
				})
				.catch((error) => console.error(error));
		} else {
			axios
				.request({ method: 'GET', url: `/groups/labels` })
				.then((response) => {
					setGroups(response.data.data);
				})
				.catch((error) => console.log(error));
		}
		return setForm({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (e: any): void => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSave = async () => {
		try {
			if (isUpdate && clientId) {
				await updateClient(clientId, form);
			} else {
				form.groupId = parentId ? +parentId : form.groupId;
				isInterested ? await createInterested(form) : await createClient(form);
			}

			handleSaveParent();
			handleClose();
		} catch (error: any) {
			if (error.response && error.response.data && error.response.data.error) {
				const { field, message }: BaseError = error.response.data.error;
				setErrors({ ...errors, [field]: getErrorMessage(message) });
			}
			if (error.code === 'ERR_NETWORK')
				setErrors({ ...errors, serverError: 'true' });
		}
	};

	//modal form ref

	return (
		<ModalForm
			open={open}
			handleClose={handleClose}
			title={title}
			formRef={formRef}
			formType={isUpdate ? FormType.EDIT : FormType.ADD}
			hasServerError={!!errors?.serverError}
		>
			{(!isUpdate || Object.keys(form).length !== 0) && (
				<Box
					component="form"
					sx={{
						'& .MuiTextField-root, .MuiFormControl-root': {
							m: 1,
							marginInline: 3,
							width: '25ch',
						},
					}}
					autoComplete="off"
					ref={formRef}
					onSubmit={(e) => {
						e.preventDefault();
						handleSave();
					}}
				>
					<TextField
						id="name"
						name="name"
						label="Nombre y apellido"
						placeholder="ej.: Luciana Perez"
						variant="standard"
						value={form.name}
						onChange={handleChange}
					/>
					<TextField
						required={!isUpdate}
						type="date"
						id="first_contact"
						name="first_contact"
						label="Fecha de contacto"
						variant="standard"
						InputLabelProps={{
							shrink: true,
						}}
						value={form?.first_contact}
						onChange={handleChange}
					/>
					<TextField
						id="contact_method"
						name="contact_method"
						select
						label="Método de contacto"
						defaultValue={form?.contact_method}
						SelectProps={{
							native: false,
						}}
						value={form?.contact_method}
						variant="standard"
						onChange={handleChange}
					>
						{contactMethodArray.map((option, index) => (
							<MenuItem value={option} key={index}>
								{option}
							</MenuItem>
						))}
					</TextField>

					{!isGroup && !isUpdate && (
						<SearchableSelect
							label="Grupo"
							items={groups}
							defaultValue={form.group?.id || form.groupId}
							valueProperty="name"
							idProperty="id"
							value={form.group?.id || form.groupId}
							onChange={handleChange}
							name="groupId"
							width="25ch"
						/>
					)}
					{isUpdate && (
						<TextField
							id="stateId"
							name="stateId"
							select
							label="Estado"
							defaultValue={''}
							SelectProps={{
								native: false,
							}}
							value={form.stateId}
							variant="standard"
							onChange={handleChange}
						>
							<MenuItem value="">---</MenuItem>
							{states?.map((state: State) => (
								<MenuItem value={state.id} key={state.id}>
									<Pill
										color={state?.label_color as Color}
										text={state?.name}
									/>
								</MenuItem>
							))}
						</TextField>
					)}
					<TextField
						id="email"
						name="email"
						label="E-mail"
						placeholder="ej.: ejemplo@mail.com"
						variant="standard"
						value={form?.email}
						onChange={handleChange}
						error={!!errors?.email}
						helperText={errors?.email}
					/>
					<TextField
						id="phone"
						name="phone"
						label="Teléfono"
						placeholder="ej.: 1123456789"
						variant="standard"
						value={form?.phone}
						error={!!errors?.phone}
						helperText={errors?.phone}
						onChange={handleChange}
					/>
					<TextField
						id="facebook"
						name="facebook"
						label="Facebook"
						placeholder="ej.: Juliana Perez"
						variant="standard"
						value={form?.facebook}
						onChange={handleChange}
					/>
					<TextField
						id="instagram"
						name="instagram"
						label="Instagram"
						value={form?.instagram}
						placeholder="ej.: Juli_prz"
						variant="standard"
						onChange={handleChange}
					/>
					{!isGroup && (
						<TextField
							id="prefered_zone"
							name="prefered_zone"
							label="Zona de interes"
							placeholder="ej.: Munro"
							variant="standard"
							onChange={handleChange}
						/>
					)}
					<TextField
						id="pregnant_childs"
						name="pregnant_childs"
						label="Embarazada / Hij@s"
						value={form?.pregnant_childs}
						placeholder="ej.: Embarazo de 6 meses"
						variant="standard"
						onChange={handleChange}
					/>
					<TextField
						fullWidth
						id="observations"
						name="observations"
						label="Observaciones"
						value={form?.observations}
						multiline
						maxRows={4}
						sx={{ width: '56ch !important' }}
						variant="standard"
						onChange={handleChange}
					/>
				</Box>
			)}
		</ModalForm>
	);
}
