import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTokenUpdate } from '../../common/contexts/tokenContext';

export default function WixLogin() {
	const navigate = useNavigate();
	const setToken = useTokenUpdate();
	const [sending, setSending] = useState(false);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const tokenParam = urlParams.get('token');

		if (tokenParam && !sending) {
			setSending(true);
			// Llama a la API con el token
			axios
				.request({
					method: 'POST',
					url: `/auth/wix_login`,
					data: { token: tokenParam },
				})
				.then((response) => {
					if (response.data) {
						setToken(response.data);
					}
				})
				.finally(() => {
					navigate('/');
					setSending(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh', // Altura completa de la ventana
				textAlign: 'center',
			}}
		>
			<p style={{ fontSize: '1.5rem', color: '#2B4EBF' }}>
				Redirigiendo a la página de inicio...
			</p>
		</div>
	);
}
