import axios from 'axios';
import { CurrentUser } from '../interfaces/current-user.interface';
import { useToken } from '../contexts/tokenContext';

export const useGetUser = () => {
	const token = useToken();
	return async (): Promise<CurrentUser | null> => {
		try {
			if (!token?.accessToken) {
				return null;
			}
			axios.defaults.headers.common[
				'Authorization'
			] = `Bearer ${token?.accessToken}`;

			const response = await axios.request<CurrentUser>({
				url: '/users/me',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			console.log(error);
			return null;
		}
	};
};
